<template>
  <div class="flex justify-center h-screen w-screen items-center">
    <form @submit.prevent="doLogin" class="w-full md:w-1/2 flex flex-col items-center">
      <!-- logo -->
      <img class="mx-auto w-1/2 pb-8" src="../assets/Okapi-logo.png" alt="logo">
      <!-- text login -->
      <!--<h1 class="text-center text-2xl font-bold text-gray-600 mb-6">LOGIN</h1>-->
      <!-- email input -->
      <div class="w-3/4 my-6 bg-red-300 p-4 text-red-700 font-semibold rounded" v-if="errors">
        {{ errors }}
      </div>
      <div class="w-3/4 mb-6">
        <input type="email" name="email"
               v-model="form.email"
               id="email" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" placeholder="User Name">
        <div v-if="$v.form.email.$dirty">
          <div class="text-left text-red-600 italic mt-1" v-if="!$v.form.email.required">Please provide an email address</div>
          <div class="text-left text-red-600 italic mt-1" v-if="!$v.form.email.email">Please provide an email address</div>
        </div>
      </div>
      <!-- password input -->
      <div class="w-3/4 mb-6">
        <input type="password" name="password"
               v-model="form.password" autocomplete=""
               id="password" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500 " placeholder="Password">
        <div v-if="$v.form.password.$dirty">
          <div class="text-left text-red-600 italic mt-1" v-if="!$v.form.password.required">Please enter a password</div>
        </div>
      </div>
      <!-- remember input -->
      <div class="w-3/4 flex flex-row">
        <label class="inline-flex items-center cursor-pointer">
          <input id="remember_me" type="checkbox"
          v-model="form.remember"
          class="form-checkbox border-0 rounded ml-1 w-5 h-5 ease-linear transition-all duration-150">
          <span class="ml-2 text-sm font-semibold">Remember Me</span>
        </label>

        <!--<div class="text-right">
          <a href="#" class="text-sm text-slate-400 hover:text-blue-500">Forgot Password?</a>
        </div>-->
      </div>
      <!-- button -->
      <div class="w-3/4 mt-4">
        <button type="submit"
                :disabled="processing"
                :class="{'opacity-50 cursor-not-allowed': processing}"
                class="py-4 bg-primary w-full rounded text-blue-50 font-bold hover:bg-blue-700 mb-4"> LOGIN</button>
<!--        <a href="#" class="text-sm text-slate-400 hover:text-blue-500 text-right">Forgot Password?</a>-->
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import store from '../store'

export default {
  metaInfo() {
    return {
      title: "Login - Okapi - Solar Financing",
      meta: [],
    };
  },
  name: "LoginView",
  data(){
    return {
      errors: null,
      form : {
        email:"",
        password:"",
        remember: false
      },
      processing: false
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
      }
    }
  },
  created() {
    //
  },
  methods: {
    ...mapActions({
      loginUser :'auth/loginUser'
    }),
    async doLogin() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return false // stop if got error
      this.processing = true
      await this.$http.get('/sanctum/csrf-cookie')
      await this.$http.post('/api/login', this.form).then((res) => {
        if (res.data.access_token) {
          localStorage.setItem('accessToken', res.data.access_token); // to store the token
          // get user authenticated
          this.loginUser().then(() => {
            this.$router.push({name: 'home'})

            if (process.env.VUE_APP_ENV === 'production'){
              const userId = store.state.auth.user.id;
              this.$mixpanel.identify(userId);
              
              // Set user properties
              this.$mixpanel.people.set({
                '$name': store.state.auth.user.name,
                '$email': store.state.auth.user.email,
              });
              
              console.log('Mixpanel identified user with user ID: ', store.state.auth.user.id, ', name: ', store.state.auth.user.name, ', email: ', store.state.auth.user.email);
              
              // Mixpanel tracking
              try {
                this.$mixpanel.track("Login", {
                  userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
                  email: store.state.auth.user.email,
                });
              } catch (error) {
                console.error('Mixpanel tracking error', error)
              }
            }
          })
        }
      }).catch((res) => {
        console.log(res)
        if (typeof res.response !== 'undefined' && res.response.status === 422) {
          this.errors = res.response.data.message
        }
      }).finally(() => {
        this.processing = false
      })
    },
  }
}
</script>

<style scoped>

</style>
