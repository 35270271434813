<template>
  <table class="min-w-full divide-y text-sm text-center">
    <thead class="text-blue-900 border-b-4 border-blue-900">
    <tr>
      <th class="px-3 pb-2">Prepayment Year</th>
      <th class="px-3 pb-2">Balance Lease Remaining (RM)</th>
      <th class="px-3 pb-2">Prepayment Discount (RM)</th>
      <th class="px-3 pb-2">Prepayment Amount (RM)</th>
    </tr>
    </thead>
    <tbody class="divide-y" ref="tableBody">
    <tr v-for="(period, index) in periods" :key="period" :class="{'bg-gray-200': period % 2 === 1}">
      <td class="px-4 py-1">{{ formatPrepaymentYear(period) }}</td>
      <td class="px-4 py-1">{{ formatNumber(getBalanceLease(period)) }}</td>
      <td class="px-4 py-1">{{ formatPrepaymentDiscount(period, index) }}</td>
      <td class="px-4 py-1 font-bold">{{ formatPrepaymentAmount(period, index) }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {CUMPRINC} from "@formulajs/formulajs";

export default {
  name: 'EarlyPrepaymentSchedule',
  props: {
    systemCost: {
      type: Number,
      required: true
    },
    systemSize: {
      type: Number,
      required: true
    },
    eirpa: {
      type: Number,
      required: true
    },
    prepaymentDiscount: {
      type: Number,
      required: true
    },
    prepaymentAmount: {
      type: Number,
      required: true
    },
    downpayment: {
      type: Number,
      required: true
    },
    monthlyPayment: {
      type: Number,
      required: true
    },
  },
  computed: {
    periods() {
      return Array.from({ length: 10 }, (_, i) => i + 1); // Adjust to start from 1 to 10
    },
    firstMonthBalLease() {
      return parseFloat(this.monthlyPayment) * 10 * 12 + parseFloat(this.downpayment);
    },
  },
  methods: {
    calculateBalanceLease(year) {
      const tenure = 10; // assuming this is a constant value
      return this.monthlyPayment * (tenure - year) * 12;
    },
    getBalanceLease(period) {
      if (period === 1) {
        return this.firstMonthBalLease;
      } else {
        return this.calculateBalanceLease(period - 1);
      }
    },
    formatNumber(number) {
      if (number != null) {
        return number.toLocaleString('en-US');
      }
      return ''; // Return empty string if number is null or undefined
    },
    formatPrepaymentYear(period) {
      const startMonth = (period - 1) * 12 + 1;
      const endMonth = period * 12;
      return `${startMonth} - ${endMonth} mths`;
    },
    formatPrepaymentDiscount(period, index) {
      if (index < 3) {
        return '-';
      } else {
        const balanceLease = this.getBalanceLease(period);
        const prepaymentAmount = this.formatPrepaymentAmount(period, index);
        const prepaymentAmountValue = Number(prepaymentAmount.replace(/,/g, ''));

        if (isNaN(prepaymentAmountValue)) {
          return '0';
        }

        const discount = balanceLease - prepaymentAmountValue;
        return this.formatNumber(isNaN(discount) ? 0 : discount);
      }
    },
    formatPrepaymentAmount(period, year) {
      if (year < 3) {
        return this.formatNumber(this.getBalanceLease(period));
      } else {
        console.log('Prepayment EIRPA',this.eirpa)
        const cumulativePrincipal = CUMPRINC(
            this.eirpa / 12,
            120,
            this.systemCost - this.downpayment,
            1,
            year * 12,
            0
        );
        const prepaymentAmount = Math.round(
            (this.systemCost - this.downpayment + cumulativePrincipal) * 1.01
        );
        return this.formatNumber(isNaN(prepaymentAmount) ? 0 : prepaymentAmount);
      }
    },
  },
};
</script>
