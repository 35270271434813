<template>
  <table class="min-w-full divide-y text-sm text-center">
    <thead class="text-blue-900 border-b-4 border-blue-900">
    <tr>
      <th id="time-period" class="px-3 pb-2">{{timePeriodHeader}}</th>
      <th class="px-3 pb-2">{{installmentHeader}}</th>
      <th class="px-3 pb-2">{{tnb_bill_savings_Header}}</th>
      <th class="px-3 pb-2">Net Savings (RM)</th>
      <th class="px-3 pb-2">Cumulative Savings (RM)</th>
    </tr>
    </thead>
    <tbody class="divide-y" ref="tableBody">
    <tr v-for="period in periods" :key="period" :class="{'bg-gray-200': period % 2 === 0}">
      <td class="px-4 py-1">{{period}}</td>
      <td class="px-4 py-1">{{ formatInstallment(instalment) }}</td>
      <td class="px-4 py-1">{{formatNumber(tnbSavings)}}</td>
      <td class="px-4 py-1 font-bold text-green-500">{{formatNumber(cashflowSavings)}}</td>
      <td class="px-4 py-1 font-bold text-green-500">{{formatNumber(cashflowSavings*period)}}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'TableComponent',
  props: {
    mode: {
      type: String,
      required: true,
      validator: value => ['month', 'year'].includes(value)
    },
    monthlyPayment: {
      type: Number,
      required: true
    },
    tnbBillSavings: {
      type: Number,
      required: true
    },
    monthlyCashflowSavings: {
      type: Number,
      required: true
    },
  },
  computed: {
    periods() {
      return this.mode === 'month' ? 12 : 10;
    },
    timePeriodHeader() {
      return this.mode === 'month' ? 'Month' : 'Year';
    },
    installmentHeader() {
      return this.mode === 'month' ? 'Monthly Instalment (RM)' : 'Annual Instalment Payments (RM)';
    },
    tnb_bill_savings_Header() {
      return this.mode === 'month' ? 'Estimated TNB Bill Savings (RM)*' : 'Estimated TNB Bill Savings (RM)';
    },
    instalment() {
      return this.mode === 'month' ? this.monthlyPayment : this.monthlyPayment*12;
    },
    tnbSavings() {
      return this.mode === 'month' ? this.tnbBillSavings : this.tnbBillSavings*12;
    },
    cashflowSavings() {
      return this.mode === 'month' ? this.monthlyCashflowSavings : this.monthlyCashflowSavings*12;
    }
  },
  methods: {
    formatNumber(number) {
      if (number != null) {
        return number.toLocaleString('en-US');
      }
      return '';
    },
    formatInstallment(installment) {
      //add minus sign if installment is not zero
      return installment !== 0 ? '-' + this.formatNumber(Math.abs(installment)) : '0';
    },
  }
}
</script>
