<template>
    <div class="w-full md:w-10/12 xl:w-1/2 mx-auto px-5 md:px-0 py-8 shadow-none md:shadow-2xl rounded-3xl bg-white my-16">
        <img class="w-2/3 md:w-2/3 lg:w-1/2 mx-auto my-6" src="../assets/Okapi-logo.png" alt="logo">
        <h2 class="text-lg md:text-2xl font-bold mb-4 text-center">Supplementary Documents</h2>
        
        <!-- TNB Bill -->
        <div class="md:w-10/12 mx-auto mb-6">
            <label for="tnb-bill" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 flex flex-row justify-between">
                <div>Past 6 months TNB Bill <span class="text-red-600">*</span></div>
            </label>
            <input multiple
                class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500"
                type="file"
                name="tnb-bill"
                id="tnb-bill"
                ref="tnb-bil"
                accept="image/*, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                v-on:change="changeFile('tnbBill', $event)"
            >
            <div v-show="$v.tnbBill.$error">
                <div class="text-left text-red-600 italic mt-1">TNB Bill is required</div>
            </div>
        </div>

        <!-- Cukai Taksiran -->
        <div class="md:w-10/12 mx-auto mb-6">
            <label for="cukai-taksiran" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 flex flex-row justify-between">
                <div>Latest Cukai Taksiran and Cukai Tanah<span class="text-red-600">*</span></div>
            </label>
            <input multiple
                class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500"
                type="file"
                name="cukai-taksiran"
                id="cukai-taksiran"
                ref="cukai-taksiran"
                accept="image/*, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                v-on:change="changeFile('cukaiTaksiran', $event)"
            >
            <div v-show="$v.cukaiTaksiran.$error">
                <div class="text-left text-red-600 italic mt-1">Cukai Taksiran and Cukai Tanah is required</div>
            </div>
        </div>

        <!-- Geran Tanah (Land Title) -->
        <div class="md:w-10/12 mx-auto mb-6">
            <label for="geran-tanah" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 flex flex-row justify-between">
                <div>Geran Tanah (Land Title) <span class="text-red-600">*</span></div>
            </label>
            <input multiple
                class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500"
                type="file"
                name="geran-tanah"
                id="geran-tanah"
                ref="geran-tanah"
                accept="image/*, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                v-on:change="changeFile('geranTanah', $event)"
            >
            <div v-show="$v.geranTanah.$error">
                <div class="text-left text-red-600 italic mt-1">Geran Tanah is required</div>
            </div>
        </div>

        <!-- Signed System Proposal -->
        <div class="md:w-10/12 mx-auto mb-6">
            <label for="signed-system-proposal" class="block text-sm md:text-lg font-medium text-gray-700 mb-4 flex flex-row justify-between">
                <div>Signed System Proposal</div>
                <!-- <span class="text-red-600">*</span> -->
            </label>
            <input multiple
                class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500"
                type="file"
                name="signed-system-proposal"
                id="signed-system-proposal"
                ref="signed-system-proposal"
                accept="image/*, .pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                v-on:change="changeFile('signedSystemProposal', $event)"
            >
            <!-- <div v-show="$v.signedSystemProposal.$error">
                <div class="text-left text-red-600 italic mt-1">Signed System Proposal is required</div>
            </div> -->
        </div>

        <!-- Submit Button -->
        <div class="md:w-10/12 mx-auto mb-6">
            <button 
                @click.prevent="submitDoc"
                class="w-full py-4 px-8 text-white rounded"
                :class="isDocSubmitting ? 'bg-gray-500' : 'bg-blue-500 hover:bg-blue-600'"
            >
                {{ isDocSubmitting ? 'Uploading your documents, please wait...' : 'Submit' }}
            </button>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import store from '../store'

export default {
    name: 'UploadDocView',
    metaInfo() {
        return {
            title: "Okapi - Solar Financing",
            meta: [],
        };
    },
    data() {
        return {
            isDocSubmitting: false,
            tnbBill: [],
            cukaiTaksiran: [],
            geranTanah: [],
            signedSystemProposal: [],
        }
    },
    validations: {
        tnbBill: {
            required
        },
        cukaiTaksiran: {
            required
        },
        geranTanah: {
            required
        },
        // signedSystemProposal: {
        //     required
        // }
    },
    methods: {
        changeFile(fileName, event) {
            if (event.target.files.length > 0) {
                this[fileName] = Array.from(event.target.files);
            }
        },
        submitDoc() {
            this.isDocSubmitting = true;
            
            const urlParams = new URLSearchParams(window.location.search);
            const hyperlink_key = urlParams.get('key');

            // Check if hyperlink key is exist
            if (hyperlink_key) {
                // Validate form
                this.$v.$touch();
                if (this.$v.$error) {
                    let domRect = document.querySelector('.text-red-600').getBoundingClientRect();
                    window.scrollTo(
                        domRect.left + document.documentElement.scrollLeft,
                        domRect.top + document.documentElement.scrollTop
                    );

                    this.isDocSubmitting = false;
                    return false;
                }

                let form = {
                    tnb_bill: this.tnbBill,
                    cukai_taksiran: this.cukaiTaksiran,
                    geran_tanah: this.geranTanah,
                    signed_system_proposal: this.signedSystemProposal,
                }

                // Convert to formData object
                let formData = new FormData();
                Object.keys(form).forEach(key => {
                    form[key].forEach((file, index) => {
                        formData.append(`${key}[${index}]`, file);
                    });
                });

                // Send request
                this.$http.post('/api/quote/upload-doc?key=' + hyperlink_key, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.data.success === true) {
                            this.isDocSubmitting = false;
                            this.$toast.success(response.data.message);

                            if (process.env.VUE_APP_ENV === 'production'){
                              // Mixpanel tracking
                              try {
                                this.$mixpanel.track('Upload Document via Hyperlink', {
                                    userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
                                    current_form: 'upload_doc',
                                    quote_id_name: `${response.data.quote_id}-${response.data.customer_name}`,
                                });
                              } catch (error) {
                                console.error('Mixpanel tracking error:', error);
                              }
                            }
                        }
                    })
                    .catch(error => {
                        this.isDocSubmitting = false;
                        this.$toast.error(error.response.data.message);
                    });
            } else {
                this.isDocSubmitting = false;
                this.$toast.error('Invalid hyperlink key');
            }
        }
    }
}
</script>