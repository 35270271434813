<template>
  <div class="w-full px-5 py-8 mx-auto my-16 bg-white shadow-none md:w-10/12 xl:w-1/2 md:px-0 md:shadow-2xl rounded-3xl">
    <!-- <button v-show="step ===1" @click="backToMap" class="flex items-center justify-center md:ml-12 border-2 rounded-md py-1 px-2 font-medium text-gray-700 text-xs md:text-sm w-[20%] md:w-[14%] lg:w-[12%] 2xl:w-[10%] 2xl:ml-20 2xl:mt-4">
      <svg class="w-5 h-5 md:mr-1" viewBox="0 0 24 24">
        <path fill="currentColor" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
      </svg>
      Back
    </button> -->
    <img class="w-2/3 mx-auto my-4 md:w-2/3 lg:w-1/2" src="../assets/Okapi-logo.png" alt="logo">

    <!-- Google Map View -->
    <!-- <div v-show="step === 0">
      <div class="mx-auto mb-6 md:w-10/12">
        <label for="address" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Address 
          <span class="text-red-600">*</span>
        </label>  this.contentWindow.print();
          <input type="text" name="address" id="address" ref="addressInput"
            class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500"
            placeholder="ex: Jalan Cheras Perdana, 43200 Cheras, Selangor, Malaysia"
            v-model.trim="address"
          >
        <div class="mt-6" style="height: 50vh; overflow: hidden; position: relative; width: 100%; display: flex; justify-content: center;">
          <div id="map" class="w-full h-full border-2"></div>
        </div>
      </div>
      <div class="mx-auto mb-6 md:w-10/12">
        <button class="w-full px-8 py-4 text-white bg-blue-500 rounded hover:bg-blue-600" @click="nextStep">Next</button>
      </div>
    </div> -->

    <!-- Step 1: Calculator View -->
    <div v-show="step === 1">
      <div class="px-8 py-8 m-2 border md:px-0 md:p md:m-10 rounded-xl">
        <div class="mb-8 text-lg font-bold text-gray-700 underline md:text-2xl">Solar Proposal</div>
<!--      <div class="mx-auto mb-6 md:w-10/12">-->
<!--        <label for="ctos-score" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Borrower CTOS Score</label>-->
<!--        <input type="number" name="ctos-score" id="ctos-score" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500"-->
<!--               placeholder="Example: 750" v-model="ctosScore">-->
<!--      </div>-->
        <div class="mx-auto mb-6 md:w-10/12">
          <label for="average-monthly-tnb-bill" class="flex flex-row justify-between block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">
            <div>Average Monthly TNB Bill (incl SST) (RM) <span class="text-red-600">*</span></div>
          </label>
          <input type="number" name="average-monthly-tnb-bill" id="average-monthly-tnb-bill" class="w-full px-8 py-4 bg-white border-2 rounded placeholder:font-semibold hover:ring-1 outline-blue-500"
                placeholder="Example: 800"
                v-model.trim="$v.avgMonthlyTnbBill.$model"
                @blur="triggerMixPanelTracking('Input made - Average TNB Bill', avgMonthlyTnbBill, 'calculator')"
          >
          <div v-if="$v.avgMonthlyTnbBill.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.avgMonthlyTnbBill.required">Average Monthly TNB Bill field is required</div>
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.avgMonthlyTnbBill.decimal">Average Monthly TNB Bill must be a valid number</div>
          </div>
        </div>
        <div class="mx-auto mb-6 md:w-10/12">
          <label for="system-size" class="flex flex-row justify-between block mb-4 text-sm font-medium text-gray-700 md:text-lg">
            <div>System Size (kwp) <span class="text-red-600">*</span></div>
          </label>
          <input type="number" name="system-size" id="system-size" class="w-full px-8 py-4 bg-white border-2 rounded placeholder:font-semibold hover:ring-1 outline-blue-500"
                placeholder="Example: 750"
                v-model.trim="$v.systemSize.$model"
                @blur="triggerMixPanelTracking('Input made - System Size', systemSize, 'calculator')">
          <!-- <select name="system-size" id="system-cost" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500"
                  v-if="!systemSizeEditable"
                  v-model.trim="$v.systemSize.$model">
            <option value="0">0</option>
            <option v-for="i in 15" :key="i" :value="i">{{ i }}</option>
          </select> -->
          <div v-if="$v.systemSize.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.systemSize.required">System Size field is required</div>
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.systemSize.decimal">System Size must be a valid number</div>
          </div>
        </div>
        <div class="mx-auto mb-6 md:w-10/12">
          <label for="system-cost" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">System Cost (RM) <span class="text-red-600">*</span></label>
          <input type="number" name="system-cost" id="system-cost"
                v-model.trim="$v.systemCost.$model"
                @blur="triggerMixPanelTracking('Input made - System Cost', systemCost, 'calculator')"
                class="w-full px-8 py-4 bg-white border-2 rounded placeholder:font-semibold hover:ring-1 outline-blue-500" placeholder="ex: 100000.00">
          <div v-if="$v.systemCost.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.systemCost.required">System Cost field is required</div>
          </div>
        </div>
        <div class="mx-auto mb-6 md:w-10/12">
          <label for="downpayment" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Upfront Payment (RM) <span class="text-red-600">*</span></label>
          <input type="number" name="downpayment" id="downpayment" class="w-full px-8 py-4 bg-white border-2 rounded placeholder:font-semibold hover:ring-1 outline-blue-500" placeholder="ex: 100000.00" v-model.trim="$v.downpayment.$model" @blur="triggerMixPanelTracking('Input made - Downpayment', downpayment, 'calculator')">
          <div v-if="$v.downpayment.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.downpayment.required">Downpayment field is required</div>
            <!-- decimal -->
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.downpayment.decimal">Downpayment field must be a decimal number</div>
          </div>
        </div>
        <div class="mx-auto mb-6 md:w-10/12">
          <label for="leaseTenor" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Lease Tenor <span class="text-red-600">*</span></label>
          <select name="leaseTenor" id="leaseTenor" class="w-full px-8 py-4 bg-white border-2 rounded placeholder:font-semibold hover:ring-1 outline-blue-500" v-model.trim="$v.leaseTenor.$model"
            @change="triggerMixPanelTracking('Input made - Lease Tenor Selection', $event.target.options[$event.target.selectedIndex].text, 'calculator')">
            <option selected disabled hidden value="">Please select</option>
            <option value="10">10 years</option>
            <option value="7">7 years</option>
          </select>
          <div v-if="$v.leaseTenor.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.leaseTenor.required">Lease tenor field is required</div>
          </div>
        </div>
        <!-- <div class="mx-auto mb-6 md:w-10/12">
          <button class="w-full px-8 py-4 text-white bg-blue-500 rounded hover:bg-blue-600" @click="calculateSubmit">Calculate</button> -->
          <!-- Clear All link button -->
          <!-- <a href="#" class="block mt-4 text-center text-blue-500" @click="clearFrom">Clear All</a>
        </div> -->
      </div>
      
      <div class="px-8 py-8 m-2 border md:px-0 md:p md:m-10 rounded-xl">
        <div class="mb-8 text-lg font-bold text-gray-700 underline md:text-2xl">Indicative Post-Solar Savings</div>
        <div class="mx-auto mb-6 md:w-10/12">
          <label for="new-monthly-tnb-bill" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Post-Solar Monthly TNB Bill (RM)</label>
          <input type="number"
                :value="newMonthlyTnbBill"
                name="new-monthly-tnb-bill" id="new-monthly-tnb-bill" class="w-full px-8 py-4 rounded bg-gray-200 placeholder:font-semibold outline-blue-500" disabled>
        </div>
        <div class="mx-auto mb-6 md:w-10/12">
          <div class="flex flex-row justify-between">
            <label for="bill-saving" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Estimated Monthly TNB Bill Savings (RM)</label>
            <!-- <a href="#" @click.prevent="toggleTnbBillSavingsEditable" class="text-xs font-semibold text-blue-500 md:text-sm">{{ (!tnbBillSavingsEditable) ? 'Click to Overwrite' : 'End Overwrite' }}</a> -->
            <a v-if="!tnbBillSavingsFocused" href="#" @click.prevent="focusOnTnbBillSavingsField" class="text-xs font-semibold text-blue-500 md:text-sm">Click to Overwrite</a>
            <div v-else class="text-xs font-semibold text-blue-500 md:text-sm">Input your Value</div>
          </div>
          <input type="number"
                v-model="tnbBillSavings"
                @focus="tnbBillSavingsFocused = true"
                @blur="handleBlurForTnbBillSavings"
                name="bill-saving" id="bill-saving" class="w-full px-8 py-4 bg-yellow-200 rounded placeholder:font-semibold hover:ring-1 outline-blue-500 focus:bg-orange-300">
        </div>
        <div class="mx-auto mb-6 md:w-10/12">
          <table class="w-full text-left bg-gray-200 rounded-md">
            <tbody>
              <tr>
                <td class="py-4 text-gray-700 font-extrabold text-base md:text-xl w-4/6 px-4">Okapi Monthly Payment</td>
                <td class="py-4 text-gray-700 font-extrabold text-right text-base md:text-xl w-2/6 px-4">
                      <span :class="{
                          'text-red-500': monthlyPayment < 0,
                          'text-blue-500': monthlyPayment > 0,
                          'text-gray-700': monthlyPayment === 0
                      }">RM {{ monthlyPayment }}</span>
                </td>
              </tr>
              <tr>
                <td class="py-4 text-gray-700 font-normal text-base md:text-xl px-4">Estimated Monthly Net Savings</td>
                <td class="py-4 text-gray-700 font-normal text-right text-base md:text-xl px-4">
                  <span :class="{
                      'text-red-500': monthlyCashflowSavings < 0,
                      'text-green-500': monthlyCashflowSavings > 0,
                      'text-gray-700': monthlyCashflowSavings === 0
                  }">
                    <span class="text-xl" v-if="monthlyCashflowSavings < 0">- </span>RM {{ Math.abs(monthlyCashflowSavings) }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-if="monthlyCashflowSavings < 0" class="pt-2 text-red-500 text-left">
            Net cashflow is negative. Unable to proceed with leasing.
          </p>
        </div>
        <button class="px-8 py-4 text-white bg-green-500 rounded hover:bg-white hover:text-green-500 inline-flex justify-center items-center mb-6" @click="openProposalFillingModal">
          Download Proposal
        </button>
        <div class="mx-auto md:w-10/12">
          <div class="grid w-full grid-cols-2 gap-2">
            <button class="w-full px-8 py-4 text-white bg-blue-500 rounded hover:bg-blue-600" @click="applyFinanceSubmit">Provide CTOS Consent on-site</button>
            <button class="w-full px-8 py-4 text-white bg-blue-500 rounded hover:bg-blue-600" @click="openModal">Provide CTOS Consent via hyperlink</button>
          </div>
          <!-- button link back to calculator -->
          <!-- <a href="#" class="block mt-4 text-center text-blue-500" @click="prevStep">Back to Calculator</a> -->
          <!-- <a href="#" class="block mt-4 text-center text-blue-500" @click="prevStep">Back to Map</a> -->
        </div>
        <!--        <button @click="printProposal">Print Proposal</button>-->
<!--        <button @click="$router.push('/download-proposal')">View Proposal</button>-->
      </div>
    </div>

    <!-- Step 2: Form View --> 
    <div v-show="step === 2" class="w-full mx-auto md:w-10/12">
      <div class="grid grid-cols-1 mb-5 text-right">
        <p class="text-xs italic">Consent_BPH_V.40_01112020</p>
      </div>
      <div class="grid grid-cols-1 mb-10 text-left">
        <div>
          <p class="font-bold">Date: <span class="font-normal">{{ todayDate }}</span></p>
        </div>
      </div>
      <div class="grid grid-cols-1 mb-10 text-left">
        <div>
          <p class="font-bold">To:</p>
          <p class="">
            Okapi Technologies Sdn Bhd <br>
            A-3-3, Plaza Bukit Jalil (Aurora Place), <br>
            No. 1, Persiaran Jalil 1, Bandar Bukit Jalil, <br>
            57000, Kuala Lumpur, Malaysia
          </p>
        </div>
        <!--<div class="w-full h-24 px-2 border-2 md:w-1/2 lg:w-1/3">
          <p>{{ this.name }}</p>
        </div>-->
      </div>
      <div class="grid grid-cols-1 mb-5 text-left">
        <div>
          <p>Dear Sir,</p>
        </div>
      </div>
      <div class="grid grid-cols-1 mb-5 text-left">
        <div>
          <p class="font-bold">Consent Authorisation</p>
        </div>
      </div>

      <div class="grid grid-cols-1 text-left">
        <div>
          <p class="mb-5">Pursuant to the Credit Reporting Agencies (CRA) Act 2010  and Central Bank of Malaysia Act 2009. I/we the undersigned do hereby give my/our consent to you and CTOS System Sdn Bhd ("CTOS"). a registered credit reporting agency under the CRA Act to process my/our company personal data.</p>
          <p class="mb-5">By this consent, I/we understand and agree that:</p>
          <!-- list -->
          <ol class="ml-5" style="list-style-type: lower-roman">
            <li class="mb-5">
              <div class="mb-2">You may conduct credit/trade check including CCRIS checks on me/us and when consent has been given individually, on our directors, shareholders, guarantors, etc. with CTOS at any time for as long as I/we have a trade relationship with you or where any dues remain unpaid and outstanding with you, for any one or more of the following purposes:</div>
              <ul class="ml-5 list-disc">
                <li>Opening of account</li>
                <li>Debt recovery</li>
                <li>Credit/Account review</li>
                <li>Credit/Account monitoring</li>
                <li>Credit/Account evaluation</li>
                <li>Legal documentation consequent to a contract or facility granted to you</li>
              </ul>
            </li>
            <li class="mb-5">You may disclose any information on my/our conduct of my/ouraccount(s) with you,to any business entity/ies for bona fide trade checking at any time. I/We am/are also aware and understand that such information will be provided to CTOS, who may in turn share such information to subscribers of their service.</li>
            <li class="mb-5">Where you require any processing of my/our application to be processed by any processing centre located outside Malaysia (including your Head Office), I/we hereby give consent to CTOS to disclose my/our credit information except CCRIS, to such locations outside Malaysia.</li>
            <li class="mb-5">Apart from the above, I/we the undersigned do give my/our consent to you and the CTOS, to process my/our personal data as per the PDPA Act.</li>
          </ol>
        </div>
      </div>

      <div class="grid grid-cols-1 mb-5 text-left">
        <p class="mb-2">Signed By</p>
        <VueSignaturePad width="100%" height="300px" ref="signaturePad" class="border-2"/>
        <div class="text-right">
          <button class="px-4 py-2 mt-5 font-bold text-blue-600 rounded" @click="undo">Undo Signature</button>
        </div>
      </div>
      <div class="grid w-full grid-cols-1 gap-6 mb-5 text-left md:grid-cols-2">
        <div class="mb-5">
          <label for="ic_front" class="block mb-2 text-sm font-medium text-left text-gray-700 md:text-lg">IC Front <span class="text-red-600">*</span></label>
          <input type="file" name="ic_front" id="ic_front" ref="ic_front"
            accept="image/*"
            class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" v-on:change="icFrontChange">
          <div v-if="$v.ic_front.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.ic_front.required">IC Front File is required</div>
          </div>
        </div>
        <div class="mb-5">
          <label for="ic_back" class="block mb-2 text-sm font-medium text-left text-gray-700 md:text-lg">IC Back <span class="text-red-600">*</span></label>
          <input type="file" name="ic_back" id="ic_back" ref="ic_back"
            accept="image/*"
            class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" v-on:change="icBackChange">
          <div v-if="$v.ic_back.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.ic_back.required">IC Back File is required</div>
          </div>
        </div>

        <div class="mb-5">
          <label for="name" class="block text-sm md:text-lg font-medium text-gray-700 mb-2 text-left">Name (Exactly as per IC/Passport) <span class="text-red-600">*</span></label>
          <input type="text" name="name" id="name" class="w-full py-4 px-8 bg-slate-200 placeholder:font-semibold rounded hover:ring-1 outline-blue-500" placeholder="Enter your name" v-model.trim="$v.name.$model" 
            @blur="triggerMixPanelTracking('Input made - Name (exactly as per IC/Passport)', name)">
          <div v-if="$v.name.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.name.required">Name field is required</div>
          </div>
        </div>
        <div class="mb-5">
          <label for="bha" class="block mb-2 text-sm font-medium text-left text-gray-700 md:text-lg">Age <span class="text-red-600">*</span></label>
          <select name="bha" id="bha" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" v-model.trim="$v.bha.$model"
            @change="triggerMixPanelTracking('Input made - Age Selection', $event.target.options[$event.target.selectedIndex].text)">
            <option></option>
            <option value="1">Under 30 years old</option>
            <option value="2">30 - 39 years old</option>
            <option value="3">40 - 49 years old</option>
            <option value="4">50 - 59 years old</option>
            <option value="5">60 years old and above</option>
          </select>
          <div v-if="$v.bha.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.bha.required">Customer Age field is required</div>
          </div>
        </div>
        <div class="mb-5">
          <label for="ic_number" class="block mb-2 text-sm font-medium text-left text-gray-700 md:text-lg">IC Number <span class="text-red-600">*</span></label>
          <input type="text" name="ic_number" id="ic_number" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" placeholder="Enter your ic number ex: xxxxxx-xx-xxxx" v-model.trim="$v.ic_number.$model"
            @blur="triggerMixPanelTracking('Input made - IC Number', ic_number)">
          <div v-if="$v.ic_number.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.ic_number.required">IC Number field is required</div>
          </div>
        </div>

        <div class="mb-5">
          <label for="business_name" class="block mb-2 text-sm font-medium text-left text-gray-700 md:text-lg">Employer/Business Name</label>
          <input type="text" name="business_name" id="business_name" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" placeholder="Enter your business name" v-model.trim="$v.business_name.$model"
            @blur="triggerMixPanelTracking('Input made - Business Name', business_name)">
        </div>

        <!-- <div class="mb-5">
          <label for="selfie_image" class="block mb-2 text-sm font-medium text-left text-gray-700 md:text-lg">Selfie Image Holding IC<span class="text-red-600">*</span></label>
          <input type="file" name="selfie_image" id="selfie_image" ref="selfie_image"
            accept="image/*"
            class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" v-on:change="selfieImageChange">
          <div v-if="$v.selfie_image.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.selfie_image.required">Selfie image holding IC is required</div>
          </div>
        </div> -->
      </div>

      <hr class="mb-6">

      <!-- Other Information -->
      <div class="my-6">
        <div class="mb-5">
          <label for="bhi" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Customer Monthly Income (RM) <span class="text-red-600">*</span></label>
          <input type="number" name="bhi" id="bhi" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500"
                placeholder="Example 10000" v-model.trim="$v.bhi.$model" @blur="triggerMixPanelTracking('Input made - Customer Monthly Income (RM)', bhi)">
          <div v-if="$v.bhi.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.bhi.required">Customer Income field is required</div>
            <!-- decimal -->
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.bhi.decimal">Customer Income field must be a decimal number</div>
          </div>
        </div>
        <div class="mb-5">
          <label for="designation" class="block mb-2 text-sm font-medium text-left text-gray-700 md:text-lg">Designation <span class="text-red-600">*</span></label>
          <input type="text" name="designation" id="designation" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" placeholder="Enter your designation" v-model.trim="$v.designation.$model"
            @blur="triggerMixPanelTracking('Input made - Designation', designation)">
          <div v-if="$v.designation.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.designation.required">Designation field is required</div>
          </div>
        </div>
        <div class="mb-5">
          <label for="phone_no" class="block mb-2 text-sm font-medium text-left text-gray-700 md:text-lg">Mobile number <span class="text-red-600">*</span></label>
          <input type="tel" name="phone_no" id="phone_no" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" placeholder="Enter your mobile number" v-model.trim="$v.phone_no.$model"
            @blur="triggerMixPanelTracking('Input made - Mobile Number', phone_no)">
          <div v-if="$v.phone_no.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.phone_no.required">Mobile number field is required</div>
          </div>
        </div>
        <div class="mb-5">
          <label for="email" class="block mb-2 text-sm font-medium text-left text-gray-700 md:text-lg">Email Address <span class="text-red-600">*</span></label>
          <input type="email" name="email" id="email" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500"
                placeholder="Enter your Email Address"
                v-model.trim="$v.email.$model"
                @blur="triggerMixPanelTracking('Input made - Email Address', email)">
          <div v-if="$v.email.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.email.required">Email Address field is required</div>
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.email.email">Email Address field must be a valid Email (eg. john@gmail.com)</div>
          </div>
        </div>
        <hr class="mb-6">
        <div class="mx-auto mb-6 md:w-full">
          <label for="address_line" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Address Line 1 <span class="text-red-600">*</span></label>
          <input type="text" name="address_line" id="address_line" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" placeholder="ex: 123, Jalan 1, Taman 1, 12345, Kuala Lumpur" v-model.trim="$v.address_line.$model"
            @blur="triggerMixPanelTracking('Input made - Address Line 1', address_line)">
          <div v-if="$v.address_line.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.address_line.required">Address Line field is required</div>
          </div>
        </div>
        <div class="mx-auto mb-6 md:w-full">
          <label for="address_line_2" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Address Line 2</label>
          <input type="text" name="address_line_2" id="address_line_2" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" v-model="address_line_2"
            @blur="triggerMixPanelTracking('Input made - Address Line 2', address_line_2)">
        </div>
        <div class="mx-auto md:w-full">
          <div class="inline-block w-full mb-6 md:w-1/2 md:pr-5">
            <label for="postcode" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Postcode <span class="text-red-600">*</span></label>
            <input type="text" name="postcode" id="postcode" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" placeholder="ex: 47500" v-model.trim="$v.postcode.$model"
              @blur="triggerMixPanelTracking('Input made - Postcode', postcode)">
            <div v-if="$v.postcode.$dirty">
              <div class="mt-1 italic text-left text-red-600" v-if="!$v.postcode.required">Postcode field is required</div>
              <!-- integer -->
              <div class="mt-1 italic text-left text-red-600" v-if="!$v.postcode.integer">Postcode field must be a integer number</div>
            </div>
          </div>
          <div class="inline-block w-full mb-6 md:w-1/2">
            <label for="city" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">City <span class="text-red-600">*</span></label>
            <input type="text" name="city" id="city" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" v-model.trim="$v.city.$model"
              @blur="triggerMixPanelTracking('Input made - City', city)">
            <div v-if="$v.city.$dirty">
              <div class="mt-1 italic text-left text-red-600" v-if="!$v.city.required">City field is required</div>
            </div>
          </div>
        </div>
        <div class="mx-auto mb-6 md:w-full">
          <label for="state" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">State <span class="text-red-600">*</span></label>
          <select name="state" id="state" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" v-model.trim="$v.state.$model"
            @change="triggerMixPanelTracking('Input made - State Selection', $event.target.options[$event.target.selectedIndex].text)">
            <option value="Johor">Johor</option>
            <option value="Kedah">Kedah</option>
            <option value="Kelantan">Kelantan</option>
            <option value="Melaka">Melaka</option>
            <option value="Negeri Sembilan">Negeri Sembilan</option>
            <option value="Pahang">Pahang</option>
            <option value="Perak">Perak</option>
            <option value="Perlis">Perlis</option>
            <option value="Pulau Pinang">Pulau Pinang</option>
            <option value="Sabah">Sabah</option>
            <option value="Sarawak">Sarawak</option>
            <option value="Selangor">Selangor</option>
            <option value="Terengganu">Terengganu</option>
            <option value="W.P. Kuala Lumpur">W.P. Kuala Lumpur</option>
            <option value="W.P. Labuan">W.P. Labuan</option>
            <option value="W.P. Putrajaya">W.P. Putrajaya</option>
          </select>
          <div v-if="$v.state.$dirty">
            <div class="mt-1 italic text-left text-red-600" v-if="!$v.state.required">State field is required</div>
          </div>
        </div>
      </div><!--End of Other Information-->

      <div class="p-5 my-6 bg-gray-50">
        <h3 class="text-lg font-semibold">Your Information</h3>
        <!-- Customer info table summary -->
        <table class="mt-4">
          <tr>
            <td class="pr-3 font-semibold">Name</td>
            <td>{{ name }}</td>
          </tr>
          <tr>
            <td class="pr-3 font-semibold">IC Number</td>
            <td>{{ ic_number }}</td>
          </tr>
          <tr>
            <td class="pr-3 font-semibold">Designation</td>
            <td>{{ designation }}</td>
          </tr>
          <tr>
            <td class="pr-3 font-semibold">Email Address</td>
            <td>{{ email }}</td>
          </tr>
          <tr>
            <td class="pr-3 font-semibold">Phone No.</td>
            <td>{{ phone_no }}</td>
          </tr>
          <tr>
            <td class="pr-3 font-semibold">Address</td>
            <td>
              {{ address_line }} <br/>
              {{ address_line_2 }} <br/>
              {{ postcode }} <br/>
              {{ city }} <br/>
              {{ state }} <br/>
            </td>
          </tr>
        </table>
      </div>
      <div class="text-left mb-8">
        <input type="checkbox" id="checkbox" v-model="checked" @change="triggerMixPanelTracking('Consent Checkbox Changed', $event.target.checked ? 'Checked' : 'Unchecked')"/>
        I confirm that all provided information and attachments are accurate.
      </div>
      <div class="mx-auto mb-6 md:w-10/12">
        <div v-if="!isFormSubmitting">
          <button @click.prevent="submit" :disabled="!checked"
                  :class="['px-4 py-2 mb-6 font-bold text-white rounded', checked ? 'bg-blue-600' : 'bg-gray-500']">Apply for Solar Instalment Package</button>
        </div>
        <div v-if="isFormSubmitting">
          <div class="px-4 py-2 mb-6 font-bold text-white bg-gray-500 rounded">Submitting your form, please wait...</div>
        </div>
        <!-- button link cancel -->
        <a href="#" @click.prevent="cancelApplication" class="px-4 py-2 text-sm font-bold text-red-500 rounded hover:text-red-700">Cancel Application</a>
      </div>
      <div class="text-xs text-gray-400 text-left	">
        Click <a href="#" class="font-bold text-blue-600 rounded underline" @click="pdpaLinkClicked">here</a> to read PDPA, Privacy, T&C
      </div>
    </div>


    <!-- Step 3: Post Form Submission-->
    <div v-show="step === 3" class="p-6">
      <div class="flex flex-col justify-center items-center h-[350px] my-12">
        <!-- <div v-if="ctosLoading" class="flex flex-col justify-center items-center h-[250px]">
          <div class="flex flex-col">
            <h2 class="text-xl font-semibold">Checking for CTOS Score ...</h2>
            <p>It might take a minute or two</p>
          </div>
        </div> -->

        <!-- CTOS approved or not-->
        <!-- <div class="grid grid-cols-1 text-left" v-if="!ctosLoading">
          <div v-if="ctosScore" class="p-5 text-lg font-semibold bg-gray-200 text-black-500">
            CTOS Information Successfully Extracted!
          </div> -->

          <!-- CTOS Band 1 & 2 -->
          <!-- <div v-if="ctosBand === 1 || ctosBand === 2" class="my-6 text-left">
            <p class="py-4 text-2xl font-bold text-center text-green-500">{{ ctosStatus }}</p>
            <p class="mb-4">Based on the information extracted from CTOS with your consent, you are 
              <span class="font-bold" :class="{'text-green-500': (ctosBand == 1 || ctosBand === 2), 'text-orange-500': ctosBand === 3 }">{{ ctosStatus }} </span> 
              for Okapi’s solar leasing programme.
            </p>
            <p class="mb-4">For the next step, kindly prepare the following:</p>
            <p>a) Latest 6 month TNB Bills</p>
            <p>b) Latest Cukai Taksiran & Cukai Tanah</p>
            <p>c) Geran Tanah (Land Title)</p>
            <p>Our installer-partner will be in touch shortly to obtain the documents from you to complete the application process.</p>
            <p>Thank you for selecting Okapi as your partner in making your home a greener one.</p>
          </div> -->

          <!-- CTOS Band 3 -->
          <!-- <div v-else-if="ctosBand === 3" class="my-6 text-left">
            <p class="py-4 text-2xl font-bold text-center text-orange-500">{{ ctosStatus }}</p>
            <p class="mb-4">Based on the information extracted from CTOS with your consent, we would 
              <span class="font-bold" :class="{'text-orange-500': (ctosBand == 1 || ctosBand === 2), 'text-orange-500': ctosBand === 3 }">require further information </span> 
              to provide you with a final decision on your eligibility for Okapi’s solar leasing programme.
            </p>
            <p class="mb-4">For the next step, kindly prepare the following:</p>
            <p>a) Latest 12 month TNB Bills</p>
            <p>b) Latest 6 month payslips</p>
            <p>c) Latest Cukai Taksiran & Cukai Tanah</p>
            <p>d) Geran Tanah (Land Title)</p>
            <p>Our installer-partner will be in touch shortly to obtain these additional documents for our consideration.</p>
            <p>Thank you for selecting Okapi as your partner in making your home a greener one.</p>
          </div> -->

          <!-- CTOS Band 4 -->
          <!-- <div v-else-if="ctosBand === 4" class="my-6 text-left">
            <p class="mb-4">Based on the information extracted from CTOS with your consent, we are unable to proceed with your application for Okapi’s solar leasing programme.</p>
            <p class="mb-4">Thank you for selecting Okapi as your partner in making your home a greener one.</p>
          </div> -->

          <!-- Failed to extract CTOS Score (No Band) -->
          <!-- <div v-else class="my-6 text-left">
            <h2 class="text-2xl font-semibold text-green-600">Successfully submitted</h2>
            <h2 class="text-xl font-semibold">Thank you for your application!</h2>
          </div>
        </div> -->
        
        <div class="flex flex-col">
          <div class="my-6 text-left">
            <h2 class="text-2xl font-semibold text-green-600">Successfully submitted</h2>
            <h2 class="text-xl font-semibold">Thank you for your application!</h2>
          </div>
          <a v-show="!formIsViaHyperlink" href="#" @click.prevent="() => step = 1" class="my-6">Back to First Page</a>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="showModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
          <div class="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
            <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
              <div >
                <div class="mt-3 text-center sm:mt-0 sm:text-left">
                  <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Provide CTOS Consent via hyperlink</h3>
                  <form>
                    <div class="my-6">
                      <label for="customerName" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Name <span class="text-red-600">*</span></label>
                      <input type="text" v-model="customerName" required name="customerName" id="customerName" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" placeholder="Enter name" @blur="triggerMixPanelTracking('Provide CTOS Consent via Hyperlink - Name input made', customerName)">
                      <div v-if="$v.customerName.$dirty">
                        <div class="mt-1 italic text-left text-red-600" v-if="!$v.customerName.required">Name field is required</div>
                      </div>
                    </div>
                    <div class="my-6">
                      <label for="customerEmail" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Email <span class="text-red-600">*</span></label>
                      <input type="text" v-model="customerEmail" required name="customerEmail" id="customerEmail" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" placeholder="Enter email to receive link" @blur="triggerMixPanelTracking('Provide CTOS Consent via Hyperlink - Email input made', customerEmail)">
                      <div v-if="$v.customerEmail.$dirty">
                        <div class="mt-1 italic text-left text-red-600" v-if="!$v.customerEmail.required">Email address field is required</div>
                        <div class="mt-1 italic text-left text-red-600" v-if="!$v.customerEmail.email">Email Address field must be a valid Email (eg. john@gmail.com)</div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
              <button @click.prevent="submitCTOSConsentViaHyperlink" :disabled="isLoading" type="button" class="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-700 sm:ml-3 sm:w-auto">Submit</button>
              <button @click="closeModal" :disabled="isLoading" type="button" class="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Second modal for copying -->
    <div v-if="showCopyModal" class="fixed inset-0 flex items-center justify-center z-50 transition-opacity bg-gray-500 bg-opacity-75">
      <div class="bg-white p-4 rounded-lg shadow-lg w-3/5">
        <p class="text-lg font-semibold">CTOS Consent link generated successfully!</p>
        <p class="mt-2">Copy the link below:</p>
        <div class="mt-2 flex items-center">
          <input type="text" v-model="generatedConsentLink" readonly class="w-full p-2 border rounded"/>
          <button @click="copyConsentLink" class="ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Copy</button>
        </div>
        <button @click="closeCopyModal" class="mt-4 w-full px-4 py-2 bg-gray-300 rounded hover:bg-gray-400">Close</button>
      </div>
    </div>

    <!-- Proposal filling Modal -->
    <div v-if="showProposalFillingModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
          <div class="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
            <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
              <div >
                <div class="mt-3 text-center sm:mt-0 sm:text-left">
                  <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Download Proposal</h3>
                  <form>
                    <div class="my-6">
                      <label for="customerName" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Name <span class="text-red-600">*</span></label>
                      <input type="text" v-model="customerName" required name="customerName" id="customerName" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" placeholder="Customer name" @blur="triggerMixPanelTracking('Download Proposal - Name input made')">
                      <div v-if="$v.customerName.$dirty">
                        <div class="mt-1 italic text-left text-red-600" v-if="!$v.customerName.required">Name field is required</div>
                      </div>
                    </div>
<!--                    <div class="my-6">-->
<!--                      <label for="customerEmail" class="block mb-4 text-sm font-medium text-left text-gray-700 md:text-lg">Email <span class="text-red-600">*</span></label>-->
<!--                      <input type="text" v-model="customerEmail" required name="customerEmail" id="customerEmail" class="w-full px-8 py-4 rounded bg-slate-200 placeholder:font-semibold hover:ring-1 outline-blue-500" placeholder="Enter homeowner’s email address">-->
<!--                      <div v-if="$v.customerEmail.$dirty">-->
<!--                        <div class="mt-1 italic text-left text-red-600" v-if="!$v.customerEmail.required">Email address field is required</div>-->
<!--                        <div class="mt-1 italic text-left text-red-600" v-if="!$v.customerEmail.email">Email Address field must be a valid Email (eg. john@gmail.com)</div>-->
<!--                      </div>-->
<!--                    </div>-->
                  </form>
                </div>
              </div>
            </div>
            <div class="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
              <button @click="printProposal" type="button" class="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-700 sm:ml-3 sm:w-auto">Submit</button>
              <button @click="closeProposalFillingModal" type="button" class="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <canvas v-show="false" id="myChart" width="500" height="230"></canvas>

    <!-- Printable Section -->
    <div ref="printable" style="display: none;"></div>
  </div>
</template>

<script async defer :src="`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&loading=async&callback=initMap`">
import { required, decimal, integer, email } from 'vuelidate/lib/validators'
import moment from 'moment'
import { Loader } from "@googlemaps/js-api-loader";
import {RATE} from "@formulajs/formulajs";
import store from '../store'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'
// import logo from '@/assets/Okapi-logo.png'

ChartJS.register(ChartJSPluginDatalabels,Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  inject: ['mixpanel'],
  name: "FormView",
  metaInfo() {
    return {
      title: "Okapi - Solar Financing",
      meta: [],
    };
  },
  async created() {
    // get winddow url ?key=abc15
    // api -> backend to get quote data and pre fill form
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('key');
     if(key){
      this.formIsViaHyperlink = true;
      await this.autoLogin(key)
      //call api to get quote data
      console.log(key)
      this.hyperlink_key = key
      const apiUrl = '/api/quote/form?key=' + key;
      console.log(apiUrl)

      this.$http.get(apiUrl).then(response => {
        const quoteData = response.data;
        this.quote_id = quoteData.data.quote_id;
        this.name = quoteData.data.customer_name;
        this.email = quoteData.data.customer_email;
        this.customerName = quoteData.data.customer_name;
        this.customerEmail = quoteData.data.customer_email;
        this.leaseTenor = quoteData.data.lease_tenor;
        this.step = 2;

        this.$nextTick(() =>{
          this.$refs.signaturePad.resizeCanvas() // need resize because v-show hides the element the canvas is not drawn
        })
      }).catch(error => {
        console.error(error);
        this.$toast.error('Unable to get the quote. Please try again later.')
      });
    }

    // get latest settings from backend
    this.$http.get('/api/settings').then(response => {
      this.tnbTiers = JSON.parse(response.data.tnbTiers)  // last updated tnbTiers on 2024-05-07
      this.kwtbb_percent = response.data.kwtbb_percent    // 1.6
      this.kwptokwh_ratio = response.data.kwptokwh_ratio
      this.sst_percent = response.data.sst_percent
      this.minCtosForApproval = parseFloat(response.data.min_ctos_score)
      this.ctosBandSetting = JSON.parse(response.data.ctos_band)
      this.eirpa = response.data.eirpa
    })
  },
  data() {
    return {
      formIsViaHyperlink: false,
      validatingIc: false,
      minCtosForApproval: 700,
      todayDate: null,
      isTnbBillSavings: false,
      sst_percent: 8, // updated on 2024-05-07
      kwtbb_percent: 1.6,
      kwptokwh_ratio: 100, // updated on 2024-05-07
      tnbTiers: null,
      eirpa: 0,
      step: 1,
      // form data
      bhi: "",
      bha: "",
      ctosScore: "",
      systemSizeEditable: false,
      avgMonthlyTnbBill: 0,
      systemSize: 0,
      systemCost: 0,
      downpayment: 0,
      leaseTenor: "",

      // estimates
      newMonthlyTnbBill: 0,
      
      tnbBillSavings: 0,
      // tnbBillSavingsEditable: false,
      tnbBillSavingsFocused: false,

      monthlyPayment: 0,

      monthlyCashflowSavings: 0,

      business_name: "",
      checked: false,

      // form data 2
      address_line: "",
      address_line_2: "",
      postcode: "",
      city: "",
      state: "",

      //form data 3
      name: "",
      ic_number: "",
      ic_front: null,
      ic_back: null,
      // selfie_image: null,
      designation: "",
      phone_no: "",
      email: "",

      // Provide CTOS Consent via hyperlink
      showModal: false,
      customerName: "",
      customerEmail: "",
      isLoading: false,
      hyperlink_key: null,

      // Provide CTOS Consent via hyperlink
      showCopyModal: false,
      generatedConsentLink: '',

      // googleMapUrl: '',
      address: '',
      googleMapsApiKey: '',
      googleMapId: '',
      markerCoord: null,
      map: null,
      geocoder: null,

      isFormSubmitting: false,
      isCheckboxChecked: false,

      ctosBandSetting: null,
      ctosBand: 0,

      //Download Proposal
      showProposalModal: false,
      showProposalFillingModal: false,
    };
  },
  validations: {
    bhi: {
      required,
      decimal
    },
    bha: {
      required,
    },
    avgMonthlyTnbBill: {
      required,
      decimal
    },
    systemSize: {
      required,
      decimal
    },
    systemCost: {
      required,
    },
    downpayment: {
      required,
      decimal
    },
    leaseTenor: {
      required,
    },
    business_name: {
      // required,
    },
    checked: {
      required,
    },
    address_line: {
      required,
    },
    postcode: {
      required,
      integer
    },
    city: {
      required,
    },
    state: {
      required,
    },
    name: {
      required,
    },
    ic_number: {
      required,
    },
    ic_front: {
      required,
    },
    ic_back: {
      required,
    },
    // selfie_image: {
    //   required,
    // },
    designation: {
      required,
    },
    phone_no: {
      required,
    },
    email: {
      required, email
    },
    customerName: {
      required,
    },
    customerEmail: {
      required,
      email,
    },
  },
  mounted() {
    this.todayDate = new moment().format("DD/MM/YYYY");
    this.googleMapsApiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    this.googleMapId = process.env.VUE_APP_GOOGLE_MAP_ID;
    // this.initMap();
  },
  computed: {

    // ctosStatus() {
    //   let ctosStatus;

    //   switch (this.ctosBand){
    //     case 1: 
    //       ctosStatus = "Approved";
    //       break;
    //     case 2:
    //       ctosStatus = "Indicatively Approved";
    //       break;
    //     case 3:
    //       ctosStatus = "Further Assessment Required";
    //       break;
    //     case 4:
    //       ctosStatus = "";
    //       break;
    //     default:
    //       ctosStatus = "";
    //       break;
    //   }

    //   return ctosStatus;
    // }
  //   monthlyKwh() {
  //     return parseFloat(this.systemSize) * this.kwptokwh_ratio;
  //   },
  //   tariffTierIndex() {
  //     if (this.systemSize > 0) {
  //       // find monthlyKwh is between which tier
  //       let tierIndex = this.tnbTiers.findIndex(
  //         (tier) => this.monthlyKwh <= tier.tier
  //       );
  //       if (tierIndex > 0) {
  //         return tierIndex - 1;
  //       } else {
  //         return tierIndex;
  //       }
  //     }
  //     return false
  //   },
  //   monthlyCashflowSavings() {
  //     if (isNaN(this.tnbBillSavings)) return 0
  //     return parseFloat(this.tnbBillSavings - this.monthlyPayment).toFixed(2)
  //   }
  },
  watch: {
    avgMonthlyTnbBill(newVal) {
      if (newVal) {
        this.outputCalculator();
      }
    },
    systemSize(newVal) {
      if (newVal) {
        this.outputCalculator();
      }
    },
    systemCost(newVal) {
      if (newVal) {
        this.outputCalculator();
      }
    },
    downpayment(newVal) {
      if (newVal) {
        this.outputCalculator();
      }
    },
    leaseTenor(newVal) {
      if (newVal) {
        this.outputCalculator();
      }
    },
    // tnbBillSavingsEditable(newVal) {
    //   if (newVal === false) {
    //     this.monthlyCashflowSavings = Math.round(this.tnbBillSavings - this.monthlyPayment);
    //     this.newMonthlyTnbBill = Math.round(this.avgMonthlyTnbBill- this.tnbBillSavings);
    //   }
    // },
    tnbBillSavings(newVal) {
      if (newVal) {
        this.monthlyCashflowSavings = Math.round(this.tnbBillSavings - this.monthlyPayment);
        this.newMonthlyTnbBill = Math.round(this.avgMonthlyTnbBill- this.tnbBillSavings);
      }
    },
    // address(newAddress) {
    //   if (newAddress) {
    //     const encodedAddress = encodeURIComponent(this.address).replace(/%20/g, '+');
    //     this.googleMapUrl = `https://www.google.com/maps/embed/v1/place?key=${this.googleMapsApiKey}&q=${encodedAddress}&zoom=19&maptype=satellite`;
    //   }
    // },
    // systemSize() {
    //   if (this.systemSize > 0) {
    //     if (this.tariffTierIndex && this.monthlyKwh) {
    //       let precedingTierCumulative = parseInt(this.tariffTierIndex) === 0 ? this.tnbTiers[this.tariffTierIndex].cumulative : this.tnbTiers[this.tariffTierIndex - 1].cumulative;
    //       let subsequentTierRmkwh = (this.tnbTiers[this.tariffTierIndex + 1]) ? this.tnbTiers[this.tariffTierIndex + 1].rmkwh : this.tnbTiers[this.tariffTierIndex].rmkwh;
    //       let serviceFees = ((parseFloat((this.sst_percent + this.kwtbb_percent)) / 100) + 1)

    //       console.log((this.tnbTiers[this.tariffTierIndex].cumulative
    //           + ((this.monthlyKwh - this.tnbTiers[this.tariffTierIndex].tier) * subsequentTierRmkwh)))

    //       console.log((precedingTierCumulative * (this.sst_percent / 100)))

    //       this.tnbBillSavings = parseFloat((this.tnbTiers[this.tariffTierIndex].cumulative
    //               + ((this.monthlyKwh - this.tnbTiers[this.tariffTierIndex].tier) * subsequentTierRmkwh))
    //           * serviceFees).toFixed(2)
    //     }
    //   }
    // },
    // downpayment() {
    //   if (this.downpayment > 0) {
    //     console.log('EIRPA', this.eirpa);

    //     const financeAmount = this.systemCost - this.downpayment
    //     this.monthlyPayment = parseFloat(this.calculateAnnuity(10, this.eirpa, financeAmount)).toFixed(2);
    //   }
    // },
    // systemCost() {
    //   if (this.systemCost > 0) {
    //     console.log('EIRPA', this.eirpa);

    //     const financeAmount = this.systemCost - this.downpayment
    //     this.monthlyPayment = parseFloat(this.calculateAnnuity(10, this.eirpa, financeAmount)).toFixed(2);
    //   }
    // },
    ic_number () {
      // check if ic_number has dashes, remove it
      if (this.ic_number.includes('-')) {
        this.ic_number = this.ic_number.replace('-', '')
      }
    }
  },
  methods: {
    focusOnTnbBillSavingsField() {
      if (!this.tnbBillSavingsFocused) {
        this.tnbBillSavingsFocused = true;
        document.getElementById("bill-saving").focus();
      }
    },
    handleBlurForTnbBillSavings() {
      this.triggerMixPanelTracking('Overwrite Calculator Input made - Estimated Monthly TNB Bill Savings', this.tnbBillSavings, 'calculator');
      this.tnbBillSavingsFocused = false;
    },
    triggerMixPanelTracking(eventName, value=null, currentForm='application_form') {
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          const trackingData = {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: currentForm,
          };
          if (value !== null) {
            trackingData.value = value;
          }
          if (this.quote_id) {
            trackingData.quote_id_name = `${this.quote_id}-${this.name}`;
          }

          this.$mixpanel.track(eventName, trackingData);
        } catch (error) {
          console.log('Mixpanel tracking error: ', error);
        }
      }
    },
    formattedAddress() {
      let address = '';

      if (this.address_line) {
        address += `${this.address_line},<br>`;
      }
      if (this.address_line_2) {
        address += `${this.address_line_2},<br>`;
      }
      if (this.postcode && this.city) {
        address += `${this.postcode} ${this.city},<br>`;
      }
      if (this.state) {
        address += this.state;
      }

      return address;
    },
    printProposal() {
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('Download Proposal - Submitted', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'application_form',
          });
        } catch (error) {
          console.log('Mixpanel tracking error: ', error);
        }
      }

      this.$v.customerName.$touch();
      //this.$v.customerEmail.$touch();
      // this.$v.address_line.$touch();
      // this.$v.postcode.$touch();
      // this.$v.city.$touch();
      // this.$v.state.$touch();

      if (this.$v.customerName.$error) {
        return;
      }
      if (!this.showProposalModal) {
        this.showProposalModal = true;
        this.$nextTick(() => {
          // this.cloneAndPrint();

          // Get chartBase64Image and fetch proposal pdf from backend
          this.getChartBase64Image();

          this.showProposalModal = false;
        });
      } else {
        this.cloneAndPrint();
      }
    },
    getChartBase64Image() {
      const canvas = document.getElementById('myChart');
      const ctx = canvas.getContext('2d');

      // Check and destroy existing chart instance
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      // Generate the chart on the temporary canvas
      this.chartInstance = new ChartJS(ctx, {
        type: 'bar',
        data: {
          labels: ['Before Solar', 'After Solar'],
          datasets: [
            {
              datalabels: {
                font: {
                  size: 15
                },
                color: 'white',
                anchor: 'center',
                align: 'center',
                formatter: function(value) {
                  if (value!==''){
                    return 'RM' + value.toLocaleString();
                  }
                  return value;
                }
              },
              label: 'TNB Bill',
              data: [this.avgMonthlyTnbBill, this.newMonthlyTnbBill],
              backgroundColor: '#ff6161',
              borderWidth: 1,
              barPercentage: 0.5,
              // barThickness: 60,
            },
            {
              datalabels: {
                font: {
                  size: 15
                },
                color: 'white',
                anchor: 'center',
                align: 'center',
                formatter: function(value) {
                  if (value!==''){
                    return 'RM' + value.toLocaleString();
                  }
                  return value;
                }
              },
              label: 'Monthly Payment',
              data: ['', this.monthlyPayment],
              backgroundColor: '#2e75b6',
              borderWidth: 1,
              barPercentage: 0.5,
            },
            {
              datalabels: {
                font: {
                  size: 15
                },
                color: '#027F02',
                anchor: 'center',
                align: 'center',
                formatter: function(value) {
                  if (value!==''){
                    return 'RM' + value.toLocaleString();
                  }
                  return value;
                }
              },
              label: 'Monthly Savings',
              data: ['',this.monthlyCashflowSavings],
              backgroundColor: '#ffffff',
              borderColor: '#027F02',
              borderWidth: 1,
              barPercentage: 0.5,
              // barThickness: 60,
            }
          ]
        },
        options: {
          responsive: false,
          plugins: {
            legend: {
              position: 'bottom',
              align: 'center',
              labels: {
                boxWidth: 10, // Adjust the width of the color box
                font: {
                  size: 15
                }
              }
            },
          },
          scales: {
            x: {
              ticks: {
                font: {
                  size: 15,
                }
              },
              stacked: true,
              grid: {
                display: false
              },
              title: {
                display: false // Hides the x-axis title
              }
            },
            y: {
              ticks: {
                font: {
                  size: 15,
                }
              },
              display: false,
              stacked: true,
              grid: {
                display: false
              },
              title: {
                display: false // Hides the x-axis title
              }
            }
          },
          animation: {
            onComplete: () => {
              const imageBase64 = canvas.toDataURL('image/png');
              this.fetchProposalFromBackend(imageBase64);
            }
          }
        }
      });
    },

    // async convertImageToBase64(imagePath) {
    //   try {
    //     const response = await fetch(imagePath);
    //     const blob = await response.blob();
    //     return new Promise((resolve, reject) => {
    //       const reader = new FileReader();
    //       reader.onloadend = () => resolve(reader.result);
    //       reader.onerror = reject;
    //       reader.readAsDataURL(blob);
    //     });
    //   } catch (error) {
    //     console.error('Error converting image to Base64:', error);
    //     return null;
    //   }
    // },

    async fetchProposalFromBackend(imageBase64) {
      // // Convert the logo into base 64
      // const convertedLogo = await this.convertImageToBase64(logo);
      // console.log('Logo base64:', convertedLogo);

      const proposalData = {
        customerName: this.customerName,
        avgMonthlyTnbBill: this.avgMonthlyTnbBill,
        newMonthlyTnbBill: this.newMonthlyTnbBill,
        monthlyPayment: this.monthlyPayment,
        tnbBillSavings: this.tnbBillSavings,
        monthlyCashflowSavings: this.monthlyCashflowSavings,
        eirpa: this.eirpa,
        systemCost: this.systemCost,
        systemSize: this.systemSize,
        downpayment: this.downpayment,
        leaseTenor: parseInt(this.leaseTenor),
        prepaymentAmount: "0",
        prepaymentDiscount: "0",
        chartImage: imageBase64,
        // logoBase64: convertedLogo,
      };

      try {
        const response = await this.$http.post('/api/quotes/generate-proposal-pdf', proposalData, {
          responseType: 'blob',
          withCredentials: true
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const formattedDate = new moment().format("YYMMDD");
        const fileName = `${formattedDate} Okapi Proposal for ${this.customerName} - ${this.systemSize} kWp ${this.leaseTenor} yrs.pdf`;

        window.parent.postMessage({
          type: 'downloadPdf',
          blob: blob,
          fileName: fileName,
        }, '*');

        this.closeProposalFillingModal();
        console.log('Proposal downloaded successfully.', response);        
      } catch (error) {
        console.error('Unable to download the proposal.', error);
        this.$toast.error('Unable to download the proposal. Please try again later.');
      }
    },
    cloneAndPrint() {
      // const customerAddress = this.formatAddress();
      const proposalData = {
        // address_line: this.address_line,
        // address_line_2: this.address_line_2,
        // postcode: this.postcode,
        // state: this.state,
        // city: this.city,
        customerName: this.customerName,
        // customerEmail: this.customerEmail,
        // customerAddress: this.customerAddress,
        avgMonthlyTnbBill: this.avgMonthlyTnbBill,
        newMonthlyTnbBill: this.newMonthlyTnbBill,
        monthlyPayment: this.monthlyPayment,
        tnbBillSavings: this.tnbBillSavings,
        monthlyCashflowSavings: this.monthlyCashflowSavings,
        // eirpa: this.eirpa,
        eirpa: RATE(this.eirpa*12, this.monthlyPayment, -(this.systemCost - this.downpayment))*12,
        systemCost: this.systemCost,
        systemSize: this.systemSize,
        downpayment: this.downpayment,
        leaseTenor: this.leaseTenor,
        prepaymentAmount: "0",
        prepaymentDiscount: "0"
      };
      let proposalDataQueryParams = btoa(JSON.stringify(proposalData));
      console.log(proposalDataQueryParams);
      // localStorage.setItem(unique_id, JSON.stringify(proposalData));

      this.closeProposalFillingModal();

      const downloadProposal = this.$router.resolve({ name: 'download-proposal', query: { proposalData: proposalDataQueryParams}});
      const newTab = window.open(downloadProposal.href, '_blank');

      newTab.onload = function() {
        newTab.onafterprint = function() {
          newTab.close();
        };
      };
    },
    formatNumber(number) {
      // Check if number is not null or undefined
      if (number != null) {
        // Use toLocaleString to format number with thousand separator
        return number.toLocaleString('en-US');
      }
      return ''; // Return empty string if number is null or undefined
    },
    async initMap() {
      const loader = new Loader({
        apiKey: this.googleMapsApiKey,
        version: "weekly",
        libraries: ["places"],
      });

      await loader.load();
      this.google = window.google;

      this.map = new this.google.maps.Map(document.getElementById("map"), {
        zoom: 19,
        mapTypeId: 'satellite',
        streetViewControl: false,
        mapTypeControl: false,
        rotateControl: true,
        mapId: this.googleMapId,
      });

      this.geocoder = new this.google.maps.Geocoder();
      this.setMarker('Jalan Cheras Perdana, 43200 Cheras, Selangor, Malaysia');

      // Initialize the Autocomplete service
      const autocomplete = new this.google.maps.places.Autocomplete(this.$refs.addressInput);
      autocomplete.bindTo('bounds', this.map);

      // When the user selects an address from the dropdown, populate the address field in the form.
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for address: '" + place.name + "'");
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          this.map.fitBounds(place.geometry.viewport);
        } else {
          this.map.setCenter(place.geometry.location);
          this.map.setZoom(19);
        }

        this.marker.setPosition(place.geometry.location);
        this.marker.setVisible(true);

        // Set the value of variable markerCoord to current address lat lng
        this.markerCoord = place.geometry.location.toJSON();
        console.log('Marker lat lng: ', this.markerCoord.lat, ',', this.markerCoord.lng); 
      });
    },

    setMarker(address) {
      this.geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          const position = results[0].geometry.location;
          this.map.setCenter(position);

          if (this.marker) {
            this.marker.setMap(null);
          }

          this.marker = new this.google.maps.Marker({
            position: position.toJSON(),
            map: this.map,
            draggable: true,
          });

          this.marker.addListener('dragend', () => {
            this.markerCoord = this.marker.getPosition().toJSON();
            console.log('Dragged marker lat lng: ', this.markerCoord.lat, ',', this.markerCoord.lng);
          });
        } else {
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });
    },
    backToMap() {
      this.step = 0;
    },
    calculateSubmit() {
    
      this.$v.systemSize.$touch();
      this.$v.systemCost.$touch();
      this.$v.downpayment.$touch();
      this.$v.leaseTenor.$touch();

      if (this.$v.systemSize.$error || this.$v.systemCost.$error || this.$v.downpayment.$error || this.$v.leaseTenor.$error) {
        return;
      }
      this.nextStep()
    },
    applyFinanceSubmit() {
      this.$v.avgMonthlyTnbBill.$touch();
      this.$v.systemSize.$touch();
      this.$v.systemCost.$touch();
      this.$v.downpayment.$touch();
      this.$v.leaseTenor.$touch();

      if (this.$v.avgMonthlyTnbBill.$error || this.$v.systemSize.$error || this.$v.systemCost.$error || this.$v.downpayment.$error || this.$v.leaseTenor.$error) {
        return;
      }

      this.nextStep()

      if (process.env.VUE_APP_ENV === 'production'){
        //Mixpanel tracking
        try {
          this.$mixpanel.track('Provide CTOS Consent On-site', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'application_form',
          });
        } catch (error) {
          console.log('Mixpanel tracking error: ', error);
        }
      }
    },
    async nextStep() {
      this.step++;

      this.$nextTick(() =>{
        if (this.step === 2) { // signatory page
          this.$refs.signaturePad.resizeCanvas() // need resize because v-show hides the element the canvas is not drawn
        }
      })

      window.scrollTo(0, 0);
    },
    prevStep() {
      this.step--;
      window.scrollTo(0, 0);
    },
    // toggleTnbBillSavingsEditable() {
    //   const eventName = this.tnbBillSavingsEditable === false ? 'Overwrite Calculator Estimated Monthly TNB Bill Savings' : 'End Overwrite';


    //   if (process.env.VUE_APP_ENV === 'production') {
    //     // Mixpanel tracking
    //     try {
    //       const trackingData = {
    //         userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
    //         current_form: 'calculator',
    //       };
    //       if (eventName === 'End Overwrite') {
    //         trackingData.amount = this.tnbBillSavings;
    //       }

    //       this.$mixpanel.track(eventName, trackingData);
    //     } catch (error) {
    //       console.log('Mixpanel tracking error: ', error);
    //     }
    //   }
    //   this.tnbBillSavingsEditable = !this.tnbBillSavingsEditable;
    // },
    outputCalculator() {
      const monthly_tnb_incl_sst = this.avgMonthlyTnbBill;
      const downpayment = this.downpayment;
      const kwtbb = this.kwtbb_percent/100;
      const recommended_system_size = this.systemSize;
      console.log('Average Monthly TNB Bill Presolar:', monthly_tnb_incl_sst)
      const tnbTiers = this.tnbTiers;
      const eirpa = this.eirpa;
      const finalEirpa = RATE(eirpa*12, this.monthlyPayment, -(this.systemCost - this.downpayment))*12
      console.log(finalEirpa);

      // Get Category A ICPT values from tnbTiers:
      let categoryA = tnbTiers.find(item => item.category === "A"); // Filter for category "A"
      let icpt_rebate_residential_tariff = categoryA.tiers.map(tier => tier.tier); // Map over the tiers array to get the tier values
      let icpt_rebate_kwh = categoryA.tiers.map(tier => tier.rmkwh); // Map over the tiers array to get the rmkwh values
      let icpt_rebate_cumulative = categoryA.tiers.map(tier => tier.cumulative); // Map over the tiers array to get the cumulative values

      // Get Category B ICPT values from tnbTiers:
      let categoryB = tnbTiers.find(item => item.category === "B");
      let no_icpt_residential_tariff = categoryB.tiers.map(tier => tier.tier);
      let no_icpt_kwh = categoryB.tiers.map(tier => tier.rmkwh);
      let no_icpt_cumulative = categoryB.tiers.map(tier => tier.cumulative);

      // Get Category C ICPT values from tnbTiers:
      let categoryC = tnbTiers.find(item => item.category === "C");
      let icpt_surcharge_residential_tariff = categoryC.tiers.map(tier => tier.tier);
      let icpt_surcharge_kwh = categoryC.tiers.map(tier => tier.rmkwh);
      let icpt_surcharge_cumulative = categoryC.tiers.map(tier => tier.cumulative);

      const finance_cost = this.systemCost - downpayment;

      const leasing_rate = eirpa/100;

      const tenure = this.leaseTenor * 12;

      const est_monthly_payment = pmt(leasing_rate, tenure, finance_cost);
      // this.monthlyPayment = est_monthly_payment;
      this.monthlyPayment = est_monthly_payment < 0 ? 0 : est_monthly_payment;

      // Calculation for monthly_tnb_incl_sst_less_kwtbb
      const monthly_tnb_incl_sst_less_kwtbb = roundUp(monthly_tnb_incl_sst / (1 + kwtbb));
      console.log('Monthly TNB Bill (incl SST less KWTBB): ', monthly_tnb_incl_sst_less_kwtbb)

      // Calculation for icpt_category
      let icpt_category;
      if (monthly_tnb_incl_sst_less_kwtbb <= 232.29) {
          icpt_category = "A";
      } else if (monthly_tnb_incl_sst_less_kwtbb > 232.29 && monthly_tnb_incl_sst_less_kwtbb <= 936.64) {
          icpt_category = "B";
      } else if (monthly_tnb_incl_sst_less_kwtbb >= 936.64) {
          icpt_category = "C";
      } else {
          icpt_category = "Error";
      }
      console.log('ICPT Category: ', icpt_category)

      // Calculation for tariff_tier
      let tariff_tier;
      switch (icpt_category) {
        case "A":
          tariff_tier = matchIndex(icpt_rebate_cumulative, monthly_tnb_incl_sst_less_kwtbb);
          break;
        case "B":
          tariff_tier = matchIndex(no_icpt_cumulative, monthly_tnb_incl_sst_less_kwtbb);
          break;
        case "C":
          tariff_tier = matchIndex(icpt_surcharge_cumulative, monthly_tnb_incl_sst_less_kwtbb);
          break;
        default:
          // Handle default case
          break;
      }
      console.log('Tariff Tier:', tariff_tier)

      // Calculation for bill_portion_highest_app_tariff
      let bill_portion_highest_app_tariff;
      if (tariff_tier === 1) {
          bill_portion_highest_app_tariff = 0;
      } else {
        switch (icpt_category) {
          case "A":
            bill_portion_highest_app_tariff = monthly_tnb_incl_sst_less_kwtbb - icpt_rebate_cumulative[tariff_tier - 2]; // Assuming tariff_tier is 1-based index
            break;
          case "B":
            bill_portion_highest_app_tariff = monthly_tnb_incl_sst_less_kwtbb - no_icpt_cumulative[tariff_tier - 2]; // Assuming tariff_tier is 1-based index
            break;
          case "C":
            bill_portion_highest_app_tariff = monthly_tnb_incl_sst_less_kwtbb - icpt_surcharge_cumulative[tariff_tier - 2]; // Assuming tariff_tier is 1-based index
            break;
          default:
            // Handle default case
            break;
        }
      }
      console.log('Bill portion at highest applicable tariff: ', bill_portion_highest_app_tariff)

      // Calculation for kwh_highest_tariff
      let kwh_highest_tariff;
      if (tariff_tier === 1) {
          kwh_highest_tariff = 0;
      } else {
        switch (icpt_category) {
          case "A":
            kwh_highest_tariff = ceilUp(bill_portion_highest_app_tariff / icpt_rebate_kwh[tariff_tier - 1]); // Assuming tariff_tier is 1-based index
            break;
          case "B":
            kwh_highest_tariff = ceilUp(bill_portion_highest_app_tariff / no_icpt_kwh[tariff_tier - 1]); // Assuming tariff_tier is 1-based index
            break;
          case "C":
            kwh_highest_tariff = ceilUp(bill_portion_highest_app_tariff / icpt_surcharge_kwh[tariff_tier - 1]); // Assuming tariff_tier is 1-based index
            break;
          default:
            // Handle default case
            break;
        }
      }
      console.log('kwh at highest tariff: ', kwh_highest_tariff)

      // Calculation for cumulative_bill_at_lower_tariff
      const cumulative_bill_at_lower_tariff = monthly_tnb_incl_sst_less_kwtbb - bill_portion_highest_app_tariff;
      console.log('Cumulative bill at lower tariffs: ', cumulative_bill_at_lower_tariff)

      // Calculation for cumulative_kwh_at_lower_tariff
      let cumulative_kwh_at_lower_tariff;
      if (tariff_tier === 1) {
          cumulative_kwh_at_lower_tariff = 0;
      } else {
          cumulative_kwh_at_lower_tariff = no_icpt_residential_tariff[tariff_tier - 2]; // Assuming tariff_tier is 1-based index
      }
      console.log('Cumulative kwh at lower tariffs: ', cumulative_kwh_at_lower_tariff)

      // Calculation for monthly_kwh
      const monthly_kwh = kwh_highest_tariff + cumulative_kwh_at_lower_tariff; // Before Solar: kWh Usage
      console.log('Before Solar: kWh Usage', monthly_kwh)

      // Calculation for solar_kwh_generated
      const solar_kwh_generated = recommended_system_size * 100; // J13 After Solar: Est. kWh generated from Solar
      console.log('After Solar: Est. kWh generated from Solar', solar_kwh_generated)

      // Calculation for net_kwh_from_the_grid
      const net_kwh = monthly_kwh - solar_kwh_generated; // J14
      console.log('Net kwh from the grid: ', net_kwh)

      // Calculation for new_icpt_category J15
      let new_icpt_category;
      if (net_kwh <= 600) {
          new_icpt_category = "A";
      } else if (net_kwh >= 601 && net_kwh <= 1500) {
          new_icpt_category = "B";
      } else if (net_kwh >= 1501) {
          new_icpt_category = "C";
      } else {
          new_icpt_category = "Error";
      }
      console.log('New ICPT Category: ', new_icpt_category)

      // Calculation for new_tariff_tier
      let new_tariff_tier = matchIndex(no_icpt_residential_tariff, net_kwh);
      console.log('New Tariff Tier: ', new_tariff_tier)

      // Calculation for new_tnb_bill
      let new_tnb_bill;
      if (new_tariff_tier === 1) {
          switch (new_icpt_category) {
              case "A":
              new_tnb_bill = net_kwh * icpt_rebate_kwh[0];
              break;
          case "B":
              new_tnb_bill = net_kwh * no_icpt_kwh[0];
              break;
          case "C":
              new_tnb_bill = net_kwh * icpt_surcharge_kwh[0];
              break;
              default:
                  // Handle default case
                  break;
          }
      } else {
          switch (new_icpt_category) {
              case "A":
                  new_tnb_bill = roundUp(icpt_rebate_cumulative[new_tariff_tier - 2] + ceilUp(net_kwh - icpt_rebate_residential_tariff[new_tariff_tier - 2]) * icpt_rebate_kwh[new_tariff_tier - 1]);
                  break;
              case "B":
                  new_tnb_bill = roundUp(no_icpt_cumulative[new_tariff_tier - 2] + ceilUp(net_kwh - no_icpt_residential_tariff[new_tariff_tier - 2]) * no_icpt_kwh[new_tariff_tier - 1]);
                  break;
              case "C":
                  new_tnb_bill = roundUp(icpt_surcharge_cumulative[new_tariff_tier - 2] + ceilUp(net_kwh - icpt_surcharge_residential_tariff[new_tariff_tier - 2]) * icpt_surcharge_kwh[new_tariff_tier - 1]);
                  break;
              default:
                  // Handle default case
                  break;
          }
      }
      console.log('New TNB Bill', new_tnb_bill)


      // Calculation for new_tnb_bill_incl_kwtbb, output to calculator
      const new_tnb_bill_kwtbb = Math.round(new_tnb_bill * (1 + kwtbb));
      this.newMonthlyTnbBill = new_tnb_bill_kwtbb < 0 ? 0 : new_tnb_bill_kwtbb;
      console.log('New TNB Bill (incl KWTBB): ', this.newMonthlyTnbBill);
      // // this.newMonthlyTnbBill = new_tnb_bill_kwtbb; (old calculation before 2024-06-11)
      // new_tnb_bill_kwtbb < 0 ? this.newMonthlyTnbBill = 0 : this.newMonthlyTnbBill = new_tnb_bill_kwtbb; // If new_tnb_bill_kwtbb is less than 0, set to 0 (new calculation on 2024-06-11)
      // console.log('New TNB Bill (incl KWTBB): ', new_tnb_bill_kwtbb)

      // Calculation for exact_tnb_bill_savings, output to calculator
      const est_tnb_bill_saving = monthly_tnb_incl_sst - this.newMonthlyTnbBill; // After Solar Est. TNB Bill
      this.tnbBillSavings = est_tnb_bill_saving;
      console.log('Exact TNB Bill savings: ', est_tnb_bill_saving);
      // const est_tnb_bill_saving = monthly_tnb_incl_sst - new_tnb_bill_kwtbb; // After Solar Est. TNB Bill
      // this.tnbBillSavings = est_tnb_bill_saving;
      // console.log('Exact TNB Bill savings: ', est_tnb_bill_saving)

      // Calculation for Est. Monthly Saving B26, output to calculator
      // const est_monthly_saving = Math.round(est_tnb_bill_saving - est_monthly_payment); // After Solar: Est. Monthly Saving (old calculation before 2024-06-11)
      const est_monthly_saving = Math.round(this.avgMonthlyTnbBill - this.newMonthlyTnbBill - this.monthlyPayment) // After Solar: Est. Monthly Saving (new calculation on 2024-06-11)
      this.monthlyCashflowSavings = est_monthly_saving;
      console.log('After Solar: Est. Monthly Payments', est_monthly_payment)
      console.log('After Solar: Est. Monthly Saving', est_monthly_saving)

      // Function to find index of first element greater than net_kwh in the array
      function matchIndex(array, value) {
          for (let i = 0; i < array.length; i++) {
              if (array[i] > value) {
                  return i + 1; // 1-based index
              }
          }
          return array.length + 1; // Return length of array + 1 if no element is greater than net_kwh
      }

      function ceilUp(number) {
        return (Math.ceil(number * 2) / 2);
      }

      function roundUp(number) {
        return Math.round(number * 100) / 100;
      }

      function pmt(rate, periods, financeCost) {
          const monthlyRate = rate / 12;
          return Math.round(financeCost * monthlyRate / (1 - Math.pow(1 + monthlyRate, -periods)))
      }
    },
    async submit() {
      this.isFormSubmitting = true;
      this.customerName = this.name;
      this.customerEmail = this.email;

      this.$v.$touch()
      if (this.$v.$error) {
        this.$nextTick(() => {
          let domRect = document.querySelector('.text-red-600').getBoundingClientRect();
          window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop
          );
        })
        this.isFormSubmitting = false;
        return false
      }

      // await this.checkCtos() // trigger ctos check

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      let form =  {
        bhi: this.bhi,
        bha: this.bha,
        // ctosScore: this.ctosScore,
        // ctosBand: this.ctosBand,
        avgMonthlyTnbBill: this.avgMonthlyTnbBill,
        systemSize: this.systemSize,
        systemCost: this.systemCost,
        downpayment: this.downpayment,
        leaseTenor: parseInt(this.leaseTenor),
        newMonthlyTnbBill : this.newMonthlyTnbBill,
        tnbBillSavings: this.tnbBillSavings,
        monthlyPayment: this.monthlyPayment,
        address_line: this.address_line,
        address_line_2: this.address_line_2,
        marker_lat_lng: JSON.stringify(this.markerCoord),
        postcode: this.postcode,
        city: this.city,
        state: this.state,
        name: this.name,
        ic_number: this.ic_number,
        ic_front: this.ic_front,
        ic_back: this.ic_back,

        business_name: this.business_name,
        checked: this.checked,

        // selfie_image: this.selfie_image,
        designation: this.designation,
        phone_no: this.phone_no,
        email: this.email,
        signature: (!isEmpty) ? data : null,
        hyperlink_key: this.hyperlink_key? this.hyperlink_key : null
      }

      // convert to formData object
      let formData = new FormData();
      Object.keys(form).forEach((key) => {
        formData.append(key, form[key]);
      })

      this.$http.post('/api/quotes', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.success) {
          this.isFormSubmitting = false;
          this.$toast.success('Successfully submitted')
          this.nextStep() // go to step 3
          // this.$router.push({ name: 'home'})
          if (process.env.VUE_APP_ENV === 'production') {
            // Mixpanel tracking
            try {
              this.$mixpanel.track('Apply for Solar Instalment Package', {
                userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
                current_form: 'application_form',
                quote_id_name: `${res.data.quote.id}-${this.name}`,
              });
            } catch (error) {
              console.log('Mixpanel tracking error: ', error);
            }
          }
        } else {
          throw new Error('Quote creation unsuccessful');
        }
      }).catch(() => {
        this.isFormSubmitting = false;
        this.$toast.error('Something went wrong while submitting')
      })
    },
    cancelApplication() {
      this.step = 1;
      if (process.env.VUE_APP_ENV === 'production') {
        // Mixpanel tracking
        try {
          this.$mixpanel.track('Cancel Application', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'application_form',
            homeowner_name: this.name ?? 'Name not available',
          });
        } catch (error) {
          console.log('Mixpanel tracking error: ', error);
        }
      }
    },
    icFrontChange(e) {
      if (e.target.files.length > 0) {
        this.triggerMixPanelTracking('IC Front Changed', e.target.files[0].name);
        this.ic_front = e.target.files[0];
        // this.icOcr(this.ic_front)
      } else {
        this.triggerMixPanelTracking('IC Front File Removed');
      }
    },
    icBackChange(e) {
      if (e.target.files.length > 0) {
        this.triggerMixPanelTracking('IC Back Changed', e.target.files[0].name)
        this.ic_back = e.target.files[0]
        // this.icOcr(this.ic_back, true)
      } else {
        this.triggerMixPanelTracking('IC Back File Removed');
      }
    },
    // selfieImageChange(e) {
    //   this.selfie_image = e.target.files[0]
    // },
    // handles ic OCR
    // icOcr(file, checkIsBack = false) {
    //   if (file) {
    //     let formData = new FormData();
    //     // append file to formdata
    //     formData.append('ic_file', file);
    //     this.$http.post('/api/ic_ocr', formData, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data'
    //       }
    //     }).then(res => {
    //       if (res.data.success && res.data.ic.length > 0) {
    //           if (checkIsBack && res.data.is_front_ic) {
    //             // if check if back is marked, and the data returned is front
    //             this.$swal({
    //               icon: 'warning',
    //               title: 'Oops...',
    //               text: 'IC detection failed. Please check the source file and reupload if necessary before proceeding.',
    //             });
    //             // // clear ic file from input
    //             // this.ic_back = null
    //             // this.$refs.ic_back.value = null
    //           }

    //           // if front ic then use
    //           if (res.data.is_front_ic) {
    //             this.name = res.data.name
    //             this.ic_number = res.data.ic.replace(/-/g, '');
    //           }

    //           // set age 
    //           if (res.data.age < 30) {
    //             this.bha = 1
    //           } else if (res.data.age >= 30 && res.data.age <= 40) {
    //             this.bha = 2
    //           } else if (res.data.age >= 41 && res.data.age <= 50) {
    //             this.bha = 3
    //           } else if (res.data.age >= 51 && res.data.age <= 60) {
    //             this.bha = 4
    //           } else if (res.data.age > 60) {
    //             this.bha = 5
    //           }
    //       } else {
    //         this.$swal({
    //           icon: 'warning',
    //           title: 'Oops...',
    //           text: 'IC detection failed. Please check the source file and reupload if necessary before proceeding.',
    //         });
    //       }
    //     })
    //   }
    // },

    // checkCtos() {
    //   // ctos loading
    //   this.ctosLoading = true;
    //   return new Promise((resolve, reject) => {
    //     this.$http.post('/api/quotes/ctos', {
    //       name: this.name,
    //       ic_number: this.ic_number
    //     }).then(res => {
    //       if (res.data.success) {
    //         this.ctosScore = parseFloat(res.data.ctos_score)
    //         this.assignCtosBand();
    //       } else {
    //         console.log('Check CTOS unsuccessfull');
    //         // this.ctosScore = 700;                     //! MUST BE COMMENTED OUT IF NOT HARDCODING CTOS SCORE
    //         // this.assignCtosBand();                    //! MUST BE COMMENTED OUT IF NOT HARDCODING CTOS SCORE
    //       }
    //       resolve();
    //     }).catch(error => {
    //       console.log('Error checking CTOS');
    //       reject(error);
    //     }).finally(() => {
    //       this.ctosLoading = false
    //     })
    //   });
    // },

    // assignCtosBand() {
    //   let minBha;
    //   let maxBha;

    //   switch (this.bha) {
    //     case "1":
    //       minBha = 0;
    //       maxBha = 29;
    //       break;
    //     case "2":
    //       minBha = 30;
    //       maxBha = 39;
    //       break;
    //     case "3":
    //       minBha = 40;
    //       maxBha = 49;
    //       break;
    //     case "4":
    //       minBha = 50;
    //       maxBha = 59;
    //       break;
    //     case "5":
    //       minBha = 60;
    //       maxBha = 100; // Cannot be null otherwise condition 'maxBha <= conditions.max_age' will always be met
    //       break;
    //   }

    //   console.log('bha value is: ', this.bha, 'minBha: ', minBha, 'maxBha: ', maxBha);
        
    //   for (let i = this.ctosBandSetting.length - 1; i >= 0; i--) {
    //     let band = this.ctosBandSetting[i]
    //     let conditions = band.conditions;

    //     let scoreConditionMet = (conditions.min_score === "" || this.ctosScore >= parseFloat(conditions.min_score)) &&
    //                             (conditions.max_score === "" || this.ctosScore <= parseFloat(conditions.max_score));
                            
    //     let ageConditionMet = (conditions.min_age === null || minBha >= conditions.min_age) &&
    //                           (conditions.max_age === null || maxBha <= conditions.max_age);

    //     if (band.band === 3) {
    //       if (scoreConditionMet || ageConditionMet) {
    //         this.ctosBand = band.band;
    //         break;
    //       }
    //     } else if (band.band === 4) {
    //       if (scoreConditionMet) {
    //         this.ctosBand = band.band;
    //         break;
    //       }
    //     } else {
    //       if (scoreConditionMet && ageConditionMet) {
    //         this.ctosBand = band.band;
    //         break;
    //       }
    //     }

    //     // if ((conditions.min_score === "" || this.ctosScore >= parseFloat(conditions.min_score)) &&
    //     //     (conditions.max_score === "" || this.ctosScore <= parseFloat(conditions.max_score)) &&
    //     //     (conditions.min_age === null || minBha >= conditions.min_age) &&
    //     //     (conditions.max_age === null || maxBha <= conditions.max_age)) {
    //     //   this.ctosBand = band.band;
    //     //   break;
    //     // }
    //   }
    //   console.log('CTOS score: ', this.ctosScore, 'CTOS band: ', this.ctosBand);
    // },
    clearFrom() {
      this.bhi = "";
      this.bha = "";
      this.ctosScore = "";
      this.systemSize = "";
      this.systemCost = "";
      this.downpayment = "";
      this.leaseTenor = "";
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    // calculateAnnuity(years, interest, amount) {
    //   // Get monthly interest
    //   const i = interest / 100 / 12;
    //   let pow = i + 1;
    //   const n = years * 12;
    //   pow = Math.pow(pow, -n);
    //   const annuity = (i / (1 - pow)) * amount;
    //   return annuity;
    // },
    editTnbSavings () {
      this.isTnbBillSavings = !this.isTnbBillSavings
    },
    openModal() {
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('Provide CTOS Consent via Hyperlink', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'application_form',
          });
        } catch (error) {
            console.log('Mixpanel tracking error: ', error);
        }
      }

      this.$v.avgMonthlyTnbBill.$touch();
      this.$v.systemSize.$touch();
      this.$v.systemCost.$touch();
      this.$v.downpayment.$touch();
      this.$v.leaseTenor.$touch();

      if (this.$v.avgMonthlyTnbBill.$error || this.$v.systemSize.$error || this.$v.systemCost.$error || this.$v.downpayment.$error || this.$v.leaseTenor.$error) {
        return;
      }
      
      this.showModal = true;
    },
    closeModal() {
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('Provide CTOS Consent via Hyperlink - Cancelled', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'application_form',
          });
        } catch (error) {
            console.log('Mixpanel tracking error: ', error);
        }
      }

      this.showModal = false;
      this.showModal = false;
      this.customerName = "";
      this.customerEmail = "";
      this.$v.$reset();
    },
    openProposalFillingModal() {
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('Download Proposal', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'application_form',
          });
        } catch (error) {
          console.log('Mixpanel tracking error: ', error);
        }
      }

      this.$v.avgMonthlyTnbBill.$touch();
      this.$v.systemSize.$touch();
      this.$v.systemCost.$touch();
      this.$v.downpayment.$touch();
      this.$v.leaseTenor.$touch();

      if (this.$v.avgMonthlyTnbBill.$error || this.$v.systemSize.$error || this.$v.systemCost.$error || this.$v.downpayment.$error || this.$v.leaseTenor.$error) {
        return;
      }

      this.showProposalFillingModal = true;

      // Touch new fields when modal is triggered
      // this.$v.customerName.$touch();
      // this.$v.customerEmail.$touch();
    },
    closeProposalFillingModal() {
      this.showProposalFillingModal = false;
      this.customerName = "";
      this.customerEmail = "";
      this.$v.$reset();
    },
    closeProposalModal() {
      this.showProposalModal = false;
    },
    submitCTOSConsentViaHyperlink() {
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('Provide CTOS Consent via Hyperlink - Submitted', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'application_form',
          });
        } catch (error) {
            console.log('Mixpanel tracking error: ', error);
        }
      }
      
      // validate modal form
      this.$v.customerName.$touch();
      this.$v.customerEmail.$touch();

      if (this.$v.customerName.$error || this.$v.customerEmail.$error) {
        return;
      }

      this.isLoading = true;

      this.$http.post('/api/quotes/sendlink', {
        avgMonthlyTnbBill: this.avgMonthlyTnbBill,
        systemSize: this.systemSize,
        systemCost: this.systemCost,
        downpayment: this.downpayment,
        leaseTenor: parseInt(this.leaseTenor),
        newMonthlyTnbBill : this.newMonthlyTnbBill,
        tnbBillSavings: this.tnbBillSavings,
        monthlyPayment: this.monthlyPayment,
        marker_lat_lng: JSON.stringify(this.markerCoord),
        name: this.customerName,
        email: this.customerEmail
      }).then(res => {
        if (res.data.success) {
          this.isLoading = false;
          this.$toast.success('CTOS Consent hyperlink Sent')
          // Store the generated consent link
          this.generatedConsentLink = res.data.hyperlink;
          this.closeModal()
          // Open the second modal with 'Copy' button
          this.showCopyModal = true;
        }
      }).catch((e) => {
        this.isLoading = false;
        this.$toast.error('Something went wrong while creating CTOS Consent hyperlink')
        console.log('error: ', e);
      })
    },

    copyConsentLink() {
      // Copy the consent link to clipboard
      try {
        window.parent.postMessage({ type: 'copy', data: this.generatedConsentLink }, '*');
        this.$toast.success('CTOS Consent hyperlink copied to clipboard');
      } catch (error) {
        console.error('Error copying to clipboard', error);
        this.$toast.error('Failed to copy CTOS Consent hyperlink');
      }
    },

    closeCopyModal() {
      this.showCopyModal = false;
    },

    autoLogin(key) {
      this.$http.post('/api/auto-login', {
        hyperlink_key: key
      }).then(res => {
        if (res.data.access_token) {
          localStorage.setItem('accessToken', res.data.access_token); // to store the token
          this.$store.dispatch('auth/loginUser').then(() => {
            // console.log('check auth')
            // console.log(this.$store.state.auth.user)
            // console.log(this.$store.state.auth.authenticated)
            // this.$router.push({ name: 'quote'})
          })
        }
      }).catch(() => {
        this.$toast.error('Something went wrong while logging in')
      })
    },
    print() {
      window.print();
    },
    pdpaLinkClicked() {
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('PDPA Link Clicked', {
            quote_id_name: `${this.quote_id ?? 'Quote ID '}-${this.customerName ?? 'and name unavailable'}`,
          });
        } catch (error) {
          console.error('Mixpanel tracking error', error)
        }
      }
    }
  },
}
</script>