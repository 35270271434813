<template>
  <div class="invisible print:visible overflow-y-auto">
    <div id="downloadProposal" ref="proposalPrintableArea" v-if="isLoading !== true" class="items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
      <div class="relative overflow-hidden text-left transition-all transform bg-white rounded-lg sm:py-8 mx-auto py-4 w-[700px] sm:w-full md:w-5/6 lg:w-3/4 xl:w-1/2 2xl:w-1/3">
        <div class="px-4 pb-4 bg-white sm:pb-4">
          <div>
            <div class="sm:mt-0 sm:text-left">
              <div>
                <div class="grid grid-cols-3 px-10 items-center">
                  <img class="col-span-2 h-10" src="../assets/Okapi-logo.png" alt="logo">
                  <div class="flex flex-col col-span-1 text-right">
                    <div class="grid grid-cols-2 gap-2">
                      <div class="cols-span-1 text-xs">Proposal Date</div>
                      <div class="cols-span-1 text-xs text-left">{{ formattedTodayDate }}</div>
                      <div class="cols-span-1 text-xs">Valid Till</div>
                      <div class="cols-span-1 text-xs text-left">{{ formattedValidTillDate }}</div>
                    </div>
                  </div>
                </div>
                <div class="px-10">
                  <div class="text-2xl">INDICATIVE LEASING PROPOSAL</div>
                  <div class="text-xs flex items-center space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z" />
                    </svg>
                    <a href="mailto:sales@okapisolar.com" class="text-blue-600 underline">sales@okapisolar.com</a>
                  </div>
                  <div class="text-xs flex items-center space-x-2 pt-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                    </svg>
                    <a href="https://okapisolar.com/" class="text-blue-600 underline">www.okapisolar.com</a>
                  </div>
                  <div class="text-right font-bold pb-1">{{ customerName }}</div>
<!--                  <div class="text-right font-bold text-[12px]">{{ customerEmail }}</div>-->
                  <hr class="h-1 mx-auto bg-blue-900 border-0 rounded mb-2.5">
                </div>
                <div class="text-center">
                  <div class="grid grid-cols-3 gap-4 text-sm font-bold text-blue-900">
                    <div class="content-center">System Size</div>
                    <div class="content-center">Upfront Payment (TNB Rebate + Deposit)</div>
                    <div class="content-center">Tenure</div>
                  </div>
                  <div class="grid grid-cols-3 gap-4 py-2 text-xl font-bold text-gray-400">
                    <div>{{systemSize}} kWp</div>
                    <div>
                      <span v-if="downpayment !== 0">RM</span>
                      {{ downpayment === 0 ? 'ZERO' : downpayment }}
                    </div>
                    <div>10 years</div>
                  </div>
                </div>
                <div class="text-center">
                  <div class="pt-5 font-bold text-blue-900">Monthly Lease Payments</div>
                  <div class="font-bold text-blue-700 text-2xl mb-5">RM{{monthlyPayment}}</div>
                </div>
                <div class="mx-auto px-12 lg:px-20 xl:px-8 2xl:px-10" >
                  <table class="table-auto w-full">
                    <tr>
                      <!-- First Column (spans 2 columns) -->
                      <td class="align-top content-center">
                        <div class="h-auto text-center w-[400px]">
                          <BarChart
                              :new-monthly-tnb-bill="newMonthlyTnbBill"
                              :monthly-cashflow-savings="monthlyCashflowSavings"
                              :avg-monthly-tnb-bill="avgMonthlyTnbBill"
                              :monthly-payment="monthlyPayment"
                          />
                        </div>
                      </td>
                      <!-- Second Column -->
                      <td class="text-center align-top">
                        <div class="flex flex-col text-center rounded">
                          <div class="text-base font-bold text-blue-900">1 month net savings*</div>
                          <div class="text-2xl font-bold pb-5 text-green-600">RM {{formatNumber(monthlyCashflowSavings)}}</div>
                          <div class="text-base font-bold text-blue-900">1 year net savings</div>
                          <div class="text-2xl font-bold pb-5 text-green-600">RM {{formatNumber(monthlyCashflowSavings*12)}}</div>
                          <div class="text-base font-bold text-blue-900">10 years net savings</div>
                          <div class="text-2xl font-bold text-green-600">RM {{formatNumber(monthlyCashflowSavings*12*10)}}</div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="container pl-12">
                  <!--Monthly Instalment Schedule for Year 1-->
                  <div class="mt-5 text-left font-bold text-blue-900">Monthly Instalment Schedule for Year 1</div>
                  <TableComponent
                      mode="month"
                      :monthlyPayment="monthlyPayment"
                      :tnbBillSavings="tnbBillSavings"
                      :monthlyCashflowSavings="monthlyCashflowSavings"
                  />
                  <div class="italic text-xs text-left">
                    *Estimated savings are calculated as advised by your Installer, and will fluctuate depending on solar panel performance and your future TNB Bills.Okapi makes no guarantees regarding these savings or benefits
                  </div>

                  <!--Annual Instalment Table-->
                  <div class="mt-10 text-left font-bold text-blue-900">Annual Instalment Schedule</div>
                  <TableComponent
                      mode="year"
                      :monthlyPayment="monthlyPayment"
                      :tnbBillSavings="tnbBillSavings"
                      :monthlyCashflowSavings="monthlyCashflowSavings"
                  />

                  <!--Early Prepayment Table-->
                  <div class="pt-10 text-left font-bold text-blue-900">Early Prepayment Schedule</div>
                  <div class="pb-5 text-sm text-left">
                    You can terminate your lease before the end of the lease period by paying an early prepayment amount. Your early prepayment amount is determined from the balance lease remaining at the beginning of the prepayment period. This early prepayment amount will be discounted from Year 4 onwards.                  </div>
                  <PrepaymentTable
                      :eirpa="eirpa"
                      :systemCost="systemCost"
                      :systemSize="systemSize"
                      :monthlyPayment="monthlyPayment"
                      :downpayment="downpayment"
                      :prepayment-amount="0"
                      :prepayment-discount="0"/>
                  <div class="pt-4 pb-24 text-xs text-left">
                    *The terms of this proposal is indicative and is subject to Okapi's credit assessment of the applicant and due dilligence on the premise for the installation of the solar system
                  </div>
                </div>

                <div class="pt-4 px-12">
                  <div class="text-lg text-blue-700 font-bold pb-2">FAQ</div>
                  <div class="text-sm pb-2">
                    <div class="text-blue-700 font-bold">What is Okapi Solar?</div>
                    <div class="pl-4">Okapi Solar is Malaysia's premier provider of leasing solutions to homeowners to install rooftop solar at zero upfront cost. We partner with more than 30 of the top solar installers nationwide to bring solar energy to your rooftop. With our leasing programme, homeowners can now save money immediately on their energy bills with no initial financial commitment.</div>
                  </div>

                  <div class="text-sm pb-2">
                    <div class="text-blue-700 font-bold">What is included in the Okapi Lease Package?</div>
                    <div class="pb-2 pl-4">
                      <div class="underline">Warranties</div>
                      <div>Our installer partners are committed to the following minimum warranties on the principal components of the solar energy system:</div>
                      <div>a) Solar Panels - 12 years on Product / 25 years on Performance</div>
                      <div>b) Inverters - 10 years</div>
                      <div>c) Workmanship / DLP - 3 years</div>
                    </div>
                    <div class="pb-2 pl-4">
                      <div class="underline">Insurance</div>
                      <div>Your home solar energy system will be covered by third party damage insurance including but not limited to lightning strike, storm damage and external damage such as falling debris.</div>
                    </div>
                    <div class="pb-2 pl-4">
                      <div class="underline">Maintenance</div>
                      <div>Maintenance of the solar energy system is not included in the lease package. The homeowners will need to engage the solar service provider to perform any maintenance and inspection of the solar energy system at their own cost.</div>
                    </div>
                  </div>

                  <div class="text-sm pb-2">
                    <div class="text-blue-700 font-bold">What happens to the solar system after the end of the lease period?</div>
                    <div class="pl-4">Upon expiry or termination of the lease, the ownership of the solar energy system will be transferred to the lease applicant. The system wholly belongs to Okapi Technologies Sdn Bhd during the tenure of the lease.</div>
                  </div>

                  <div class="text-sm pb-2">
                    <div class="text-blue-700 font-bold">What are the requirements to qualify for Okapi Solar Lease?</div>
                    <div class="pl-4">
                      <div>We first check on your CTOS score for the initial approval. Upon the initial indicative approval, we will require the following documents for additional verification:</div>
                      <div>a) 6 months of latest TNB bills</div>
                      <div>b) Cukai Tanah of your home</div>
                      <div>c) Geran Tanah of your home</div>
                      <div>We may request for additional documentation as part of our verification process.</div>
                    </div>
                  </div>

                  <div class="text-sm pb-2">
                    <div class="text-blue-700 font-bold">Are the monthly payments fixed?</div>
                    <div class="pl-4">Yes, your monthly payments are fixed for the entire lease tenure. Please note that the monthly payment amount shown on page 1 is indicative and subject to the final system cost as proposed by our installer partner after assessment of the site and roof conditions.</div>
                  </div>

                  <div class="text-sm pb-40">
                    <div class="text-blue-700 font-bold">When are the monthly payments due?</div>
                    <div class="pl-4">Your first monthly payment is due on the 1st day of the following calendar month after the NEM welcome letter. All subsequent monthly payments are due on the 1st day of succeeding calendar months.</div>
                  </div>

                  <div class="text-sm pt-5">
                    <div class="text-lg text-blue-700 font-bold">DISCLAIMER</div>
                    <div class="pl-4">
                      <div>The application for the lease is subject to Know Your Customer (KYC) and credit verification checks by Okapi Technologies Sdn Bhd. The decision by Okapi Technologies on the approval of the lease application is final.</div>
                      <div>The monthly payment amount stated in page 1 is indicative and is subject to the final system cost as proposed by our installer partner after assessment of the site and roof conditions.</div>
                      <div>The savings and benefits from the photovoltaic energy generating system may vary based on factors such as weather conditions and energy consumption patterns, and Okapi Technologies Sdn Bhd makes no guarantees regarding these savings or benefits.</div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
require('@/assets/tailwind.css');
import PrepaymentTable from "@/components/PrepaymentTable.vue";
import TableComponent from "@/components/TableComponent.vue";
import BarChart from "@/components/BarChart.vue";
import moment from "moment/moment";

export default {
  name: "DownloadProposal",
  components:{
    TableComponent,
    BarChart,
    PrepaymentTable
  },
  data() {
    return {
      validatingIc: false,
      minCtosForApproval: 700,
      todayDate: null,
      isTnbBillSavings: false,
      sst_percent: 8, // updated on 2024-05-07
      kwtbb_percent: 1.6,
      kwptokwh_ratio: 100, // updated on 2024-05-07
      tnbTiers: null,
      eirpa: 0,
      step: 1,
      // form data
      bhi: "",
      bha: "",
      ctosScore: "",
      systemSizeEditable: false,
      avgMonthlyTnbBill: 0,
      systemSize: 0,
      systemCost: 0,
      downpayment: 0,

      // estimates
      newMonthlyTnbBill: 0,

      tnbBillSavings: 0,
      tnbBillSavingsEditable: false,

      monthlyPayment: 0,

      monthlyCashflowSavings: 0,

      business_name: "",
      checked: false,

      // form data 2
      address_line: "",
      address_line_2: "",
      postcode: "",
      city: "",
      state: "",

      //form data 3
      name: "",
      ic_number: "",
      ic_front: null,
      ic_back: null,
      // selfie_image: null,
      designation: "",
      phone_no: "",
      email: "",

      // Provide CTOS Consent via hyperlink
      showModal: false,
      customerName: "",
      customerEmail: "",
      isLoading: false,
      hyperlink_key: null,

      // googleMapUrl: '',
      address: '',
      googleMapsApiKey: '',
      googleMapId: '',
      markerCoord: null,
      map: null,
      geocoder: null,

      isFormSubmitting: false,
      isCheckboxChecked: false,

      ctosBandSetting: null,
      ctosBand: 0,

      //Download Proposal
      // customerAddress: "",
      showProposalModal: false,
      showProposalFillingModal: false,
    };
  },
  computed: {
    formattedTodayDate() {
      return moment(this.todayDate).format("DD MMM YYYY");
    },
    formattedValidTillDate() {
      return moment(this.todayDate).add(1, 'month').format("DD MMM YYYY");
    }
  },
  created() {
    this.todayDate = new Date();

    this.isLoading = true;
    let parsedData = this.$route.query.proposalData;
    parsedData = atob(parsedData);
    parsedData = JSON.parse(parsedData);
    // const unique_id = this.$route.query.proposalId;
    // const localData = localStorage.getItem(unique_id) //proposalData
    // const parsedData = JSON.parse(localData);

    this.eirpa = parsedData.eirpa ?? this.eirpa;
    this.avgMonthlyTnbBill = parsedData.avgMonthlyTnbBill ?? this.avgMonthlyTnbBill;
    this.systemSize = parseFloat(parsedData.systemSize) ?? parseFloat(this.systemSize);
    this.systemCost = parseFloat(parsedData.systemCost) ?? parseFloat(this.systemCost);
    this.downpayment = parsedData.downpayment ?? this.downpayment;
    this.newMonthlyTnbBill = parsedData.newMonthlyTnbBill ?? this.newMonthlyTnbBill;
    this.tnbBillSavings = parsedData.tnbBillSavings ?? this.tnbBillSavings;
    this.monthlyPayment = parsedData.monthlyPayment ?? this.monthlyPayment;
    this.monthlyCashflowSavings = parsedData.monthlyCashflowSavings ?? this.monthlyCashflowSavings;
    this.customerName = parsedData.customerName ?? this.customerName;
    this.customerEmail = parsedData.customerEmail ?? this.customerEmail;
    // this.address_line = parsedData.address_line ?? this.address_line;
    // this.address_line_2 = parsedData.address_line_2 ?? this.address_line_2;
    // this.postcode = parsedData.postcode ?? this.postcode;
    // this.city = parsedData.city ?? this.city;
    // this.state = parsedData.state ?? this.state;
    this.isLoading = false;

    // this.customerAddress = this.formatAddress();
    // console.log(this.customerAddress)
  },
  mounted () {
    setTimeout(() => window.print(), 2000);
    // Format the date as YYMMDD
    const today = new Date();
    const formattedDate = today.toISOString().slice(2, 10).replace(/-/g, '');
    document.title = `${formattedDate} Okapi Proposal for ${this.customerName} - ${this.systemSize} kWp`;
  },
  methods: {
    formatNumber(number) {
      // Check if number is not null or undefined
      if (number != null) {
        // Use toLocaleString to format number with thousand separator
        return number.toLocaleString('en-US');
      }
      return ''; // Return empty string if number is null or undefined
    },
  }
}
</script>

<style type="text/css" media="print">
@page {
  size: auto; /* auto is the initial value */
  margin: 30px; /* Set margin to zero */
}

body {
  line-height: 1;
  background-color: #ffffff !important;
}
</style>