<template>
  <div class="home">
    <div class="container mx-auto h-screen grid content-center">
      <div class="flex flex-col justify-center items-center mt-12">
        <div @click="createQuote" class="w-1/2 m-auto mx-auto py-4 bg-primary w-full rounded text-blue-50 font-bold hover:bg-blue-700">
          Create a new Quote
        </div>
        <a href="#" class="m-auto py-4 text-blue-500 font-semibold"
           @click.prevent="logout">
          Log Out
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import store from '../store'

export default {
  name: 'HomeView',
  metaInfo() {
    return {
      title: "Okapi - Solar Financing",
      meta: [],
    };
  },
  components: {
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('userId');

    if (userId) {
      this.autoGenerateToken(userId);
    }
  },
  methods: {
    createQuote() {
      this.$router.push({ name: 'quote' })
      if (process.env.VUE_APP_ENV === 'production'){
        // Mixpanel tracking
        try {
          this.$mixpanel.track('Create a New Quote', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'application_form',
          });
        } catch (error) {
          console.error('Mixpanel tracking error', error)
        }
      }
    },
    autoGenerateToken(userId) {
      this.$http.post('/api/auto-generate-token', {
        userId: userId
      }).then(res => {
        if (res.data.access_token) {
          localStorage.setItem('accessToken', res.data.access_token);
          this.$store.dispatch('auth/loginUser').then(() => {
            this.$router.push({ name: 'home'})
          })
        }
      }).catch(() => {
        this.$toast.error('Something went wrong while logging in')
        this.$router.push({ name: 'login' })
      })
    },
    logout () {
      if (this.$store.state.auth.user.id && this.$store.state.auth.user.name) {
        if (process.env.VUE_APP_ENV === 'production') {
          // Capture user details before logging out
          const userId_Name = `${store.state.auth.user.id} ${store.state.auth.user.name}`;

          // Mixpanel tracking
          try {
            this.$mixpanel.track('Logout', {
              userId_Name: userId_Name,
              current_form: 'application_form',
            });

            // Reset Mixpanel identity management
            this.$mixpanel.reset();
            console.log('Mixpanel identity reset');
          } catch (error) {
            console.error('Mixpanel tracking error', error)
          }
        }

        this.$store.dispatch('auth/logout')
        this.$router.push({ name: 'login' })
      } else {
        this.$store.dispatch('auth/logout')
        this.$router.push({ name: 'login' })
        console.log('User data not available for Mixpanel tracking.');
      }
    }
  }
}
</script>
