import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './assets/tailwind.css'
import router from './router'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import VueSignaturePad from 'vue-signature-pad';
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueMeta from 'vue-meta'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMixpanel from 'vue-mixpanel';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
Vue.use(VueMeta)
Vue.use(VueToast);
Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VueSignaturePad);
Vue.use(VueMixpanel, {
    token: process.env.VUE_APP_MIXPANEL_TOKEN
  });

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true

const base = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

Vue.prototype.$http = base;
Vue.prototype.$http.interceptors.request.use(
    config => {
        // // set content-type application/json
        // config.headers = Object.assign({
        //     'Content-Type': 'application/json'
        // });
        let accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers = Object.assign({
                Authorization: `Bearer ${accessToken}`,
            }, config.headers);
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
