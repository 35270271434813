<template>
  <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'

ChartJS.register(ChartJSPluginDatalabels,Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    monthlyPayment: {
      type: Number,
      required: true
    },
    avgMonthlyTnbBill: {
      type: Number,
      required: true
    },
    monthlyCashflowSavings: {
      type: Number,
      required: true
    },
    newMonthlyTnbBill: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      chartData: {
        labels: ['Before Solar', 'After Solar'],

        datasets: [
          {
            datalabels: {
              color: 'white',
              anchor: 'center',
              align: 'center',
              formatter: function(value) {
                if (value!==''){
                  return 'RM' + value.toLocaleString();
                }
                return value;
              }
            },
            label: 'TNB Bill',
            data: [this.avgMonthlyTnbBill, this.newMonthlyTnbBill],
            backgroundColor: '#ff6161',
            borderWidth: 1,
            barPercentage: 0.5,
            // barThickness: 60,
          },
          {
            datalabels: {
              color: 'white',
              anchor: 'center',
              align: 'center',
              formatter: function(value) {
                if (value!==''){
                  return 'RM' + value.toLocaleString();
                }
                return value;
              }
            },
            label: 'Monthly Payment',
            data: ['', this.monthlyPayment],
            backgroundColor: '#2e75b6',
            borderWidth: 1,
            barPercentage: 0.5,
          },
          {
            datalabels: {
              color: '#027F02',
              anchor: 'center',
              align: 'center',
              formatter: function(value) {
                if (value!==''){
                  return 'RM' + value.toLocaleString();
                }
                return value;
              }
            },
            label: 'Monthly Savings',
            data: ['',this.monthlyCashflowSavings],
            backgroundColor: '#ffffff',
            borderColor: '#027F02',
            borderWidth: 1,
            barPercentage: 0.5,
            // barThickness: 60,
          }
        ]
      },

      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            labels: {
              boxWidth: 10, // Adjust the width of the color box
            }
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false
            },
            title: {
              display: false // Hides the x-axis title
            },
          },
          y: {
            display: false,
            stacked: true,
            grid: {
              display: false
            },
            title: {
              display: false // Hides the x-axis title
            }
          }
        }
      }
    }
  }
}
</script>
